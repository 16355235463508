import { Directive, Input, Component, HostListener } from '@angular/core';

// export for convenience.
export { RouterLink } from '@angular/router';

/* tslint:disable:directive-class-suffix */
@Directive({
    selector: '[routerLink]'
})
export class RouterLinkDirectiveStub {
    @Input('routerLink') linkParams: any;
    navigatedTo: any = null;

    @HostListener('click') onClick() {
        this.navigatedTo = this.linkParams;
    }
}

@Component({selector: 'router-outlet', template: ''})
export class RouterOutletStubComponent {
}

/// Dummy module to satisfy Angular Language service. Never used.
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        RouterOutletStubComponent,
        RouterLinkDirectiveStub
    ]
})
export class RouterStubsModule {
}
