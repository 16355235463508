import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";

export function equalValidator(validateEqual: string, reverse: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        //self value
        const currentElementValue = control.value;
        //target element against which current element's value will be compared
        const targetElement = control.root.get(validateEqual);

        // value not equal, return error object
        if (targetElement && currentElementValue !== targetElement.value && !reverse) {
            return {
                validateEqual: false
            }
        }

        //value is equal and is reverse, meaning that first element is being changed
        if (targetElement && currentElementValue === targetElement.value && reverse) {
            //if targetElement has errors
            if (targetElement.errors) {
                //delete the validateEqual error
                delete targetElement.errors['validateEqual'];
                //check if any other errors remain on the targetElement, if none, call setErrors to restore the valid state on the element
                if (!Object.keys(targetElement.errors).length)
                    targetElement.setErrors(null);
            }
        }

        //value not equal and reverse, meaning that first element is being changed
        if (targetElement && currentElementValue !== targetElement.value && reverse) {
            //set errors on the targetElement
            targetElement.setErrors({
                validateEqual: false
            })
        }

        return null;
    }
}