import { NgModule } from '@angular/core';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
    AuthGuard,
    CommonService,
    CanDeactivateGuard,
    Config,
    DialogService,
    ProgressService,
    User,
    equalValidator
} from '../shared/index';

@Component({
    selector: 'user-details',
    templateUrl: './user.component.html'
})
export class UserComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(private router: Router, private progressService: ProgressService, private commonService: CommonService, private route: ActivatedRoute, private dialogService: DialogService, private formBuilder: FormBuilder) {
        this.user = new User();
        this.copyOfUserData = new User();
        this.newPasswordValidators = [
            Validators.maxLength(50),
            Validators.pattern(Config.passwordValidationRegex),
            equalValidator("confirm_password", true)
        ];
        this.confirmPasswordValidators = [
            Validators.maxLength(50),
            Validators.pattern(Config.passwordValidationRegex),
            equalValidator("new_password", false)
        ];
        this.createForm();
    }

    uuid: string = null;
    addMode: boolean = false;
    isAdminUser: boolean = false;
    isMyProfile: boolean = false;
    subscriptions: Subscription[] = [];
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};
    user: User;
    copyOfUserData: User;
    roles: Array<any> = [];
    httpDataPayload: any = null;
    changePassword: boolean = false;
    userForm: FormGroup;
    newPasswordValidators: Array<ValidatorFn>;
    confirmPasswordValidators: Array<ValidatorFn>;


    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    ngOnInit() {
        //receive id from the URL and call loadData
        this.route.params.subscribe(params => {
            this.uuid = params['id'];
            this.loadData();
        });

        //get access control data
        this.accessControlData = this.commonService.getAccessControlData("system_management");
    }


    ngAfterViewInit() {
        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Checks if there are any unsaved changes when the user tries to navigate away from current section
     *
     * @author Sukhdeep Singh
     * @return {Promise|boolean} value indicating user's decision to discard the changes or not
     */
    canDeactivate(): Promise<boolean> | boolean {
        let dataUnchanged: boolean = true;

        //if the user doesn't have write permissions to this section or it's an addMode then let the navigation continue
        if (Config.skipCanDeactivateGuard || this.accessControlData.w != '1' || this.addMode)
            return true;

        const userFormData = Object.assign({}, this.user, this.userForm.value);
        // Check if the data has changed
        for (let key in this.user) {
            if (userFormData[key] != this.copyOfUserData[key]) {
                dataUnchanged = false;
                break;
            }
        }

        //check if user was trying to change the password and haven't saved yet
        if (this.changePassword && userFormData.new_password && userFormData.confirm_password && userFormData.new_password == userFormData.confirm_password)
            dataUnchanged = false;

        //Allow synchronous navigation (`true`) if the data hasn't changed
        if (dataUnchanged)
            return true;

        // Otherwise ask the user with the dialog service and return its promise which resolves to true or false when the user decides
        return this.dialogService.confirm();
    }

    /**
     * Creates the reactive form object and instantiates all the form fields
     *
     * @author Sukhdeep Singh
     */
    createForm() {
        this.userForm = this.formBuilder.group({
            first_name: new FormControl(this.user.first_name, [Validators.required, Validators.maxLength(100), Validators.pattern(Config.nameValidationRegex)]),
            last_name: new FormControl(this.user.last_name, [Validators.required, Validators.maxLength(100), Validators.pattern(Config.nameValidationRegex)]),
            email: new FormControl(this.user.email, [Validators.required, Validators.maxLength(255), Validators.email]),
            username: new FormControl(this.user.username, [Validators.required, Validators.maxLength(80)]),
            active: [this.user.active],
            role_id: new FormControl(null, [Validators.required]),
            new_password: ['', this.newPasswordValidators],
            confirm_password: ['', this.confirmPasswordValidators]
        });
    }

    /**
     * Load user data and all the dropdowns data | initialize some material elements | set necessary values for shared modules
     *
     * @author Sukhdeep Singh
     */
    loadData(): void {
        this.userForm.reset();
        //check if user details is currently logged in user's profile or not
        this.isMyProfile = this.uuid == this.commonService.getCurrentUserUuid();
        this.addMode = this.isAddMode();
        this.subscriptions.push(this.commonService.makeRequest({
            "request": "user_details",
            "id": this.commonService.getCurrentUserUuid()
        }).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.verifyUser()
        ));

        if (this.roles.length == 0)
            this.getDropDownData(Config.roles, true);

        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Use service to retrieve user profile details
     *
     * @author Sukhdeep Singh
     */
    getUser(): void {
        if (!this.isAddMode()) {
            this.progressService.show();

            //get user profile data
            this.subscriptions.push(this.commonService.makeRequest({
                "request": "user_details",
                "id": this.uuid
            }).subscribe(
                httpDataPayload => this.httpDataPayload = httpDataPayload,
                error => this.handleHttpError(error),
                () => this.bindUser()
            ));

            //when editing an existing user, if 3rd index exists, meaning a required validator exists, then remove it because password isn't a required field while editing the user
            if (this.newPasswordValidators[3]) {
                this.newPasswordValidators.pop();
                this.confirmPasswordValidators.pop();
            }
        } else {
            this.user = new User();
            this.copyOfUserData = new User();
            //when adding a new user, password and confirm password are required fields
            this.confirmPasswordValidators[3] = Validators.required;
            this.newPasswordValidators[3] = Validators.required;
            this.userForm.patchValue(this.user);
        }
        this.userForm.controls['new_password'].setValidators(this.newPasswordValidators);
        this.userForm.controls['confirm_password'].setValidators(this.confirmPasswordValidators);
        this.userForm.controls['new_password'].updateValueAndValidity();
        this.userForm.controls['confirm_password'].updateValueAndValidity();
    }

    /**
     * Check if the current logged in user is administrator of the Application or not and take appropriate actions
     *
     * @author Sukhdeep Singh
     */
    verifyUser(): void {
        const record = this.httpDataPayload && this.httpDataPayload.record ? this.httpDataPayload.record : null;
        if (record) {
            //check if the user is an administrator
            if (!this.commonService.isAdminUser(record)) {
                this.checkNonAdminUser();
            } else {
                this.isAdminUser = true;
                //if the admin user is viewing his own profile, just bind the already received data, otherwise call `getUser` to retrieve user's details
                if (this.uuid == this.commonService.getCurrentUserUuid())
                    this.bindUser();
                else
                    this.getUser();
            }
        } else {
            this.checkNonAdminUser();
        }
    }

    /**
     * Take appropriate actions for non admin users visiting this section
     *
     * @author Sukhdeep Singh
     */
    checkNonAdminUser(): void {
        //if the current logged in user is not administrator and is trying to add a new user, do not let it happen. Only administrators can add new users
        if (this.isAddMode()) {
            M.toast({
                html: "You do not have enough permissions to add new user",
                displayLength: Config.messageIntervalNormal,
                classes: "error"
            });
            this.router.navigate(['/dashboard']);
        } else if (this.uuid == this.commonService.getCurrentUserUuid()) {
            //if the user is trying to view his own profile
            this.bindUser();
        } else {
            //non admin user cannot view other user's profile
            M.toast({
                html: "You can only view you own profile",
                displayLength: Config.messageIntervalNormal,
                classes: "error"
            });
            this.router.navigate(['/dashboard']);
        }
    }

    /**
     * Parses the received user details data and assigns to the user array
     *
     * @author Sukhdeep Singh
     */
    bindUser(): void {
        const record = this.httpDataPayload && this.httpDataPayload.record ? this.httpDataPayload.record : null;
        if (record) {
            this.user = new User(record);
            this.userForm.patchValue(this.user);
            this.copyOfUserData = new User(record);
            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
        }
    }

    /**
     * Checks if it is an add new user request
     *
     * @author Sukhdeep Singh
     * @return {boolean} whether its an addMode or not
     */
    isAddMode(): boolean {
        return (!this.uuid || this.uuid == "new");
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {object} data data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Use the commonService to retrieve data for dropdowns, receive the results and pass it to bindDropDownData function
     *
     * @author Sukhdeep Singh
     * @param {string} table name of the database table which holds the contents for dropdown
     * @param {boolean} noBlank whether to have a blank record in the dropdown or not
     */
    getDropDownData(table: string, noBlank?: boolean): void {
        const params = {
            "table": table,
            "request": "dropdown"
        };

        if (noBlank)
            params['no_blank'] = "1";

        this.subscriptions.push(this.commonService.fetchDropDownData(params).subscribe(
            (data) => {
                if (table == Config.roles)
                    this.roles = data;
            },
            error => this.handleHttpError(error)
        ));
    }


    /**
     * Save user details
     *
     * @author Sukhdeep Singh
     */
    save(): void {
        this.progressService.show();

        if (this.addMode || this.userForm.value.role_id != this.copyOfUserData.role_id)
            this.user.role = this.commonService.getName(this.roles, this.userForm.value.role_id);

        const params: Object = Object.assign({}, this.user, this.userForm.value);

        //if the user had made changes to the password, pass it in params
        if (this.addMode || (this.changePassword && params['confirm_password'])) {
            params['password'] = params['confirm_password'];
        }

        //make sure that disabled/hidden items aren't changed if the user isn't admin
        if (!this.isAdminUser) {
            params['role_id'] = this.copyOfUserData.role_id;
            params['role'] = this.copyOfUserData.role;
            params['active'] = this.copyOfUserData.active;
            params['username'] = this.copyOfUserData.username;
        }

        params['active'] = params['active'] ? 1 : 0;
        params["request"] = this.addMode ? "user_add" : "user_edit";

        this.subscriptions.push(this.commonService.makeRequest(params).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.saveCallBack()
        ));

    }

    /**
     * save() function callback
     *
     * @author Sukhdeep Singh
     */
    saveCallBack(): void {
        const record = this.httpDataPayload && this.httpDataPayload.record ? this.httpDataPayload.record : null;
        if (record) {
            M.toast({
                html: "User saved successfully",
                displayLength: Config.messageIntervalNormal
            });
            this.progressService.hide();
            this.changePassword = false;
            //if a new user was being added, then redirect to the newly created user's details page, otherwise just update the user data as returned from server
            if (this.addMode) {
                this.router.navigate(['/user', record.uuid]);
            } else
                this.bindUser();
        } else {
            this.handleError(this.httpDataPayload);
        }
    }

    /**
     * Toggles the change password input fields visibility
     *
     * @author Sukhdeep Singh
     */
    toggleChangePassword(): void {
        this.changePassword = !this.changePassword;
        this.userForm.get("new_password").setValue("");
        this.userForm.get("confirm_password").setValue("");
    }
}

@NgModule({
    imports: [
        RouterModule.forChild([
            {path: 'user/:id', component: UserComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]}
        ])
    ],
    exports: [
        RouterModule
    ]
})

export class UserRoutingModule {
}
