import { Injectable } from '@angular/core';

/**
 * Async modal dialog service
 */
@Injectable()
export class DialogService {

    private discardChanges: boolean = false;

    /**
     * Asks user to confirm an action
     *
     * @param {string} message: the message to show in the confirmation model
     * @return `Promise` : resolving to `true`=confirm or `false`=cancel
     */
    confirm(message?: string): Promise<boolean> {
        //create a promise that resolves when the model is closed
        const decision = new Promise<boolean>((resolve, reject) => {
            //set the confirmation message if available
            if (message)
                jQuery("#modal_confirm_navigation h5").text(message);
            const modalElement = document.querySelector("#modal_confirm_navigation");
            const modalInstance = M.Modal.init(modalElement, {
                onCloseEnd: () => {
                    //resolve the promise when the model is closed
                    resolve(this.discardChanges);
                    //set the discardChanges back to false after the promise is resolved
                    this.discardChanges = false;
                }
            });
            modalInstance.open();
        });

        //handle yes button click event and set the discardChanges to true.
        jQuery("#discardChangesYesBtn").on("click", () => {
            this.discardChanges = true;
        });

        //handle no button click event and set the discardChanges to false.
        jQuery("#discardChangesNoBtn").on("click", () => {
            this.discardChanges = false;
        });

        //return the promise containing user's decision.
        //Notice that this return statement does not return the decision promise immediately, instead it waits till the promise is resolved and then returns it.
        //This promise is resolved when the model closes
        return decision;
    }

}