import { NgModule } from '@angular/core';

import { ActivityLogComponent, AccessControlComponent, ApiLogComponent, CompanySettingsComponent, ColorSchemeComponent, SessionComponent, UsersComponent, SMRoutingModule } from './index';
import { SharedModule } from '../shared/index';

@NgModule({
    imports: [
        SharedModule,
        SMRoutingModule
    ],
    declarations: [
        ActivityLogComponent,
        AccessControlComponent,
        ApiLogComponent,
        CompanySettingsComponent,
        SessionComponent,
        UsersComponent,
        ColorSchemeComponent
    ]
})
export class SMModule { }
