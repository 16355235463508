import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginService } from '../login/login.service';
import { LoginModule } from '../login/login.module';
import { HomeModule } from '../home/home.module';
import { UserModule } from '../user-profile/user.module';
import { SMModule } from '../system-management/index';


import {
  SharedModule,
  SpinnerService,
  ProgressService,
  CommonService,
  DialogService,
  DataRefreshService
} from '../shared/index';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LoginModule,
    UserModule,
    HomeModule,
    SMModule,

    AppRoutingModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    LoginService,
    SpinnerService,
    ProgressService,
    CommonService,
    DialogService,
    DataRefreshService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
