import { CommonService, Record, Records, Config } from "./index";
import { ResponseOptions } from '@angular/http';
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { generateFormattedDropdownData } from '../angular-test-helpers/index';

export class MockCommonService extends CommonService {
    public returnInvalidResponse: boolean = false;
    public returnInvalidHttpResponse: boolean = false;
    public returnNoResponse: boolean = false;
    public loggedIn: boolean = true;
    public currentUserId: number = 1;
    public currentUserUuid: string = "ktE3pA-pQSIAj-omsr2z-9oP00r";
    public sessionId: string = "1";
    public adminUser: boolean = true;
    public apiKey: string = "asdhgshASdsuygJMD18dywujkh23bjkxOn0QsMUYs7xNKK6HS";
    public currentUserName: string = "admin";
    public currentUserEmail: string = "sds@gonetex.com";
    public currentUserFullName: string = "Admin User";
    public sessionExpired: boolean = false;
    public accountSuspended: boolean = false;
    public underMaintenance: boolean = false;

    constructor() {
        super(null);
    }

    isLoggedIn(): boolean {
        return this.loggedIn;
    }

    getCurrentUserId(): number {
        return this.currentUserId;
    }

    getCurrentUserUuid(): string {
        return this.currentUserUuid;
    }

    getSessionId(): string {
        return this.sessionId;
    }

    isAdminUser(): boolean {
        return this.adminUser;
    }

    getApiKey(): string {
        return this.apiKey;
    }

    getCurrentUserName(): string {
        return this.currentUserName;
    }

    getCurrentUserEmail(): string {
        return this.currentUserEmail;
    }

    getCurrentUserFullName(): string {
        return this.currentUserFullName;
    }

    isSessionExpired(): boolean {
        return this.sessionExpired;
    }

    isAccountSuspended(): boolean {
        return this.accountSuspended;
    }

    isUnderMaintenance(): boolean {
        return this.underMaintenance;
    }

    getAccessControlData(sectionName: string = ""): any {
        const accessParamsData = [
            { "module": "system_management", "r": "1", "w": "1", "d": "1", "x": "1" },
            { "module": "order_log", "r": "1", "w": "1", "d": "1", "x": "1" },
            { "module": "outlet_inventory", "r": "1", "w": "1", "d": "1", "x": "1" }
        ];

        if (sectionName) {
            for (let i: number = 0, len: number = accessParamsData.length; i < len; i++) {
                if (accessParamsData[i]['module'] == sectionName)
                    return accessParamsData[i];
            }
        }
        return accessParamsData;
    }

    getNetworkErrorResponse() {
        return super.getNetworkErrorResponse(500, "Internal Server Error");
    }

    makeRequest(params: Object): Observable<Records | Record | null | Object> {
        if (this.returnInvalidResponse)
            return of({ 'response': 'invalid' });
        if (this.returnNoResponse)
            return of(null);
        if (this.returnInvalidHttpResponse)
            return this.throwHttpError(this.getNetworkErrorResponse());

        let responseBody: Object | Record | Records;
        switch (params['request']) {
            case 'login':
                responseBody = this.generateLoginResponse();
                break;
            case 'logout':
                responseBody = this.generateLogoutResponse();
                break;
            case 'user_details':
                responseBody = this.generateUserDetailsResponse();
                break;
            case 'role_list':
                responseBody = this.generateRolesResponse();
                break;
            case 'role_details':
                responseBody = this.generateRoleDetailsResponse();
                break;
            case 'module_list':
                responseBody = this.generateModulesResponse();
                break;
            default:
                responseBody = this.generateErrorResponse();
                break;
        }

        return of(responseBody);
    }

    fetchDropDownData(params: any = {}): Observable<Array<any>> {
        return of(generateFormattedDropdownData());
    }

    /**
     * Generates error response object with customizable message
     *
     * @param {string} message message to be returned in the error object
     * @return {Object} Error object containing code and message properties
     */
    generateErrorResponse(message?: string): Object {
        return {
            'error': {
                'message': message || 'No data found for this request, Please add data in the mock service',
                'code': 0
            }
        }
    }

    /**
     * Generates mock data for the login request
     *
     * @return {Object} containing a data expected by the login component
     */
    generateLoginResponse(): Object {
        return {
            'record': {
                'expire_time': '2025-01-01 00:00:00',
                'login_time': '2018-01-01 00:00:00',
                'first_name': 'Admin',
                'last_name': 'User',
                'access_params': [
                    { "module": "system_management", "r": "1", "w": "1", "d": "1", "x": "1" },
                    { "module": "customers", "r": "1", "w": "1", "d": "1", "x": "1" },
                    { "module": "products", "r": "1", "w": "1", "d": "1", "x": "1" }
                ]
            }
        }
    }

    /**
     * Generates mock data for the logout request
     *
     * @return {Object} containing a data expected by the login component
     */
    generateLogoutResponse(): Object {
        return {
            'message': 'success'
        }
    }

    /**
     * Returns response for user details request
     *
     * @return {Record}
     */
    generateUserDetailsResponse(): Record {
        return {
            "record": {
                "id": "1",
                "uuid": this.adminUser ? this.getCurrentUserUuid() : "asiduasuyi83278s7sh783472487y6sad",
                "username": "Loghost",
                "first_name": "Netex",
                "last_name": "Enterprises",
                "email": "sds@gonetex.com",
                "active": "1",
                "role_id": this.adminUser ? "1" : "2",
                "role": this.adminUser ? "Administrator" : "Guest",
                "created_date": "2017-11-21 16:19:52",
                "last_login_time": "2018-05-06 09:44:21"
            }
        }
    }

    /**
     * Returns response for role list request
     *
     * @return {Records}
     */
    generateRolesResponse(): Records {
        return {
            records: [
                {
                    record: {
                        "id": "1",
                        "uuid": "IKErYq-SqIRWc-IqR6oZ-8ldS1j",
                        "name": "Administrator",
                        "active": "1"
                    }
                },
                {
                    record: {
                        "id": "2",
                        "uuid": "SpvL74-G93JIG-Up7ZTa-W4XQBq",
                        "name": "Guest",
                        "active": "1"
                    }
                }
            ]
        }
    }

    /**
     * Returns response for module list request
     *
     * @return {Records}
     */
    generateModulesResponse(): Records {
        return {
            records: [
                {
                    record: {
                        "id": "1",
                        "name": "customers",
                        "active": "1"
                    }
                },
                {
                    record: {
                        "id": "2",
                        "name": "products",
                        "active": "1"
                    }
                },
                {
                    record: {
                        "id": "3",
                        "name": "system_management",
                        "active": "1"
                    }
                }
            ]
        }
    }

    /**
     * Returns response for user details request
     *
     * @return {Record}
     */
    generateRoleDetailsResponse(): Record {
        return {
            "record": {
                "id": "1",
                "uuid": this.getCurrentUserUuid(),
                "name": "Guest",
                "active": "1",
                "role_modules": [
                    {
                        "id": "1",
                        "uuid": "bRL5yA-YLoL1u-F0HeZ4-Q1igG8",
                        "role_id": "2",
                        "role": "Guest",
                        "module_id": "3",
                        "module": "system_management",
                        "r": "1",
                        "w": "1",
                        "d": "1",
                        "x": "0",
                        "active": "1"
                    },
                    {
                        "id": "2",
                        "uuid": "WcOOwl-InIrWZ-X6gGpe-hZ9yo4",
                        "role_id": "2",
                        "role": "Guest",
                        "module_id": "2",
                        "module": "products",
                        "r": "1",
                        "w": "1",
                        "d": "1",
                        "x": "0",
                        "active": "1"
                    },
                    {
                        "id": "3",
                        "uuid": "l2Y0Aq-g0qp83-945XXX-bGt2Yx",
                        "role_id": "2",
                        "role": "Guest",
                        "module_id": "1",
                        "module": "customers",
                        "r": "1",
                        "w": "1",
                        "d": "1",
                        "x": "0",
                        "active": "1"
                    }
                ]
            }
        }
    }
}
