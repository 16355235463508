import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CanDeactivateGuard, AuthGuard } from '../shared/index';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {path: '', redirectTo: '/login', pathMatch: 'full'},
      {path: '**', redirectTo: '/dashboard', canActivate: [AuthGuard]}
    ])
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuard,
    AuthGuard
  ]
})

export class AppRoutingModule {
}
