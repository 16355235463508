import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/index';
import { Observable } from 'rxjs';

//login service class
@Injectable()
export class LoginService extends CommonService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    //create a new event emitter which will emit login status when user logs in successfully
    @Output() notifyLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Emit the successful login status which can be used by any component who wants to know if the user logged in successfully (such as app.component.ts file)
     *
     * @author Sukhdeep Singh
     */
    loginSuccessful(): void {
        this.notifyLogin.emit(true);
    }

    /**
     * Emit the Unsuccessful login status which can be used by any component who wants to know if for e.g. session has expired or simply user isn't logged in
     *
     * @author Sukhdeep Singh
     */
    notLoggedIn(): void {
        this.notifyLogin.emit(false);
    }

    /**
     * Makes a login request to the server and return the response
     *
     * @author Sukhdeep Singh
     * @param {string} username : username entered by user
     * @param {string} password : password entered by user
     * @return Observable : an observable for server response
     */
    login(username: string, password: string): Observable<any> {
        return this.makeRequest({
            "username": username,
            "password": password,
            "request": "login"
        });
    }

    /**
     * Makes an api call to log the user out and return the string response if any
     *
     * @author Sukhdeep Singh
     * @return Observable : an observable for server response
     */
    logout(): Observable<any> {
        return this.makeRequest({
            "request": "logout"
        });
    }
}