import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ActivityLogComponent, AccessControlComponent, ApiLogComponent, CompanySettingsComponent, ColorSchemeComponent, SessionComponent, UsersComponent } from './index';
import { AuthGuard, CanDeactivateGuard } from '../shared/index';

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'system/activity-log', component: ActivityLogComponent, canActivate: [AuthGuard] },
            { path: 'system/session', component: SessionComponent, canActivate: [AuthGuard] },
            { path: 'system/access-control', component: AccessControlComponent, canActivate: [AuthGuard] },
            { path: 'system/settings', component: CompanySettingsComponent, canActivate: [AuthGuard], canDeactivate : [CanDeactivateGuard] },
            { path: 'system/users', component: UsersComponent, canActivate: [AuthGuard] },
            { path: 'system/color-scheme', component: ColorSchemeComponent, canActivate: [AuthGuard] },
            { path: 'system/api-log', component: ApiLogComponent, canActivate: [AuthGuard] }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class SMRoutingModule { }
