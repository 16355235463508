export class App {
	id: number;
	name: string;
	active: boolean;
	api_key: string;
	private_key: string;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.name = params.name || null;
		this.active = !(params.active === false || params.active == "0");
		this.api_key = params.api_key || null;
		this.private_key = params.private_key || null;
	}
}