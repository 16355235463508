import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Config, CommonService } from './index';
import * as M from "materialize-css/dist/js/materialize";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private commonService: CommonService) {
    }

    redirectUrl: string = "";

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Store the attempted URL for redirecting
        this.redirectUrl = state.url;
        //if the user is logged in return true
        if (this.commonService.isLoggedIn()) {
            return true;
        }

        //show the error message that session is expired or is invalid
        M.toast({
            html : "You are not logged in or your session has expired. Please login to continue.",
            displayLength : Config.messageIntervalLong,
            classes : "error"
        });

        // Navigate to the login page
        this.router.navigate(['/login']);
        return false;
    }
}
