import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Subscription, ReplaySubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Config, ProgressService, CommonService, Pager, RoleModule } from '../shared/index';

@Component({
    selector: 'access-control',
    templateUrl: './access-control.component.html'
})
export class AccessControlComponent implements OnInit, OnDestroy, AfterViewInit {
    accessControlForm: FormGroup;
    objPager: Pager;
    subscriptions: Subscription[] = [];
    accessControls: ReplaySubject<any[]>;
    modules = [];
    searchTermFormControl: FormControl;
    httpDataPayload: any = null;
    orderBy: string = "name";
    orderByDir: string = "asc";
    noResults: boolean = false;
    accessControl = {"active": "1", "name": "", "id": "", "uuid": "", "role_modules": []};
    deleteName: string = "";
    deleteId: string = null;
    roleModules: RoleModule[] = [];
    selectAllRead: boolean = false;
    selectAllWrite: boolean = false;
    selectAllDelete: boolean = false;
    selectAllExport: boolean = false;
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};

    constructor(private progressService: ProgressService, private router: Router, private commonService: CommonService, private formBuilder: FormBuilder) {
        this.objPager = new Pager({pagerTitle: "role"});
        this.searchTermFormControl = new FormControl();
        this.accessControls = new ReplaySubject<any[]>();
        this.createForm();
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
        this.getAccessControls();
        this.getModules();


        this.searchTermFormControl.valueChanges.pipe(
            debounceTime(Config.searchDebounceInterval),
            distinctUntilChanged(),
            switchMap((term: string) => {
                this.resetPager();
                return this.getDataListAsObservable();
            })).subscribe(httpDataPayload => {
            this.httpDataPayload = httpDataPayload;
            this.bindAccessControls();
        }, error => this.handleHttpError(error));
    }

    ngAfterViewInit() {
        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Creates the reactive form object and instantiates all the form fields
     *
     * @author Sukhdeep Singh
     */
    createForm() {
        this.accessControlForm = this.formBuilder.group({
            name: new FormControl("", [Validators.required, Validators.maxLength(255)]),
            select_all_read: [false],
            select_all_write: [false],
            select_all_export: [false],
            select_all_delete: [false],
            role_modules: this.formBuilder.array([])
        });

        this.subscriptions.push(this.accessControlForm.get("select_all_read").valueChanges.subscribe((newValue: boolean) => this.toggleAllPermissions(newValue, "r")));
        this.subscriptions.push(this.accessControlForm.get("select_all_write").valueChanges.subscribe((newValue: boolean) => this.toggleAllPermissions(newValue, "w")));
        this.subscriptions.push(this.accessControlForm.get("select_all_export").valueChanges.subscribe((newValue: boolean) => this.toggleAllPermissions(newValue, "x")));
        this.subscriptions.push(this.accessControlForm.get("select_all_delete").valueChanges.subscribe((newValue: boolean) => this.toggleAllPermissions(newValue, "d")));
    }

    /**
     * Uses service to retrieve access control list
     *
     * @author Sukhdeep Singh
     */
    getAccessControls(): void {
        this.subscriptions.push(this.getDataListAsObservable().subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.bindAccessControls()
        ));
    }

    /**
     * Uses the service to make http call and returns the Observable received from the service
     *
     * @author Sukhdeep Singh
     * @return {Observable} Observable returned by the http request
     */
    getDataListAsObservable(): Observable<any> {
        this.progressService.show();
        return this.commonService.makeRequest(this.getParams());
    }

    /**
     * Receives the data from server and assigns to accessControls array
     *
     * @author Sukhdeep Singh
     * @callback `getAccessControl`
     */
    bindAccessControls(): void {
        const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
        //if server sent some data, add it to the accessControls array
        if (records) {
            const accessControls = [];
            //last record in the response array is pager information
            const pagerData = records[records.length - 1];
            const totalRecords = pagerData && pagerData.page_data && parseInt(pagerData.page_data.result_count) ? parseInt(pagerData.page_data.result_count) : 0;
            if (totalRecords != this.objPager.resultCount) {
                this.objPager.resultCount = totalRecords;
                this.commonService.updatePager(this.objPager, true);
            }
            //check if search returned any data and set the boolean variable accordingly
            if (totalRecords > 0) {
                //remove the pager information from response array leaving it with only accessControls
                records.pop();
                this.noResults = false;
                //loop through the response array and add values to accessControls array
                for (let i = 0, len = records.length; i < len; i++)
                    accessControls.push(records[i].record);
            } else
                this.noResults = true;

            this.accessControls.next(accessControls);
            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
            this.noResults = true;
        }
    }

    /**
     * Get parameters when calling to get access control list

     * @return {Object} object containing all the parameters required for access control list request
     */
    getParams(): any {
        return {
            "request": "role_list",
            "term": this.searchTermFormControl.value,
            "page_num": this.objPager.pageNum,
            "rows_per_page": this.objPager.rowsPerPage,
            "order_by": this.orderBy,
            "order_by_dir": this.orderByDir
        };
    }

    /**
     * Uses service to retrieve modules list
     *
     * @author Sukhdeep Singh
     */
    getModules(): void {
        this.progressService.show();
        this.subscriptions.push(this.commonService.makeRequest({'request': 'module_list'}).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => {
                const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
                //if server sent some data, add it to the modules array
                if (records) {
                    this.modules = [];
                    for (let i = 0, len: number = records.length - 1; i < len; i++)
                        this.modules.push(records[i].record);
                    this.progressService.hide();
                } else
                    this.handleError(this.httpDataPayload);
            }
        ));
    }

    /**
     * Reset pager to initial values
     *
     * @author Sukhdeep Singh
     */
    resetPager(): void {
        this.commonService.resetPager(this.objPager);
    }

    /**
     * Clear search and retrieve a fresh list of access controls
     *
     * @author Sukhdeep Singh
     */
    clearSearch(): void {
        this.searchTermFormControl.setValue("", {onlySelf: true, emitEvent: false});
        this.orderBy = "name";
        this.orderByDir = "asc";
        this.resetPager();
        this.getAccessControls();
    }


    /**
     * Open up new tab for exporting the data in chosen format
     *
     * @author Sukhdeep Singh
     * @param {string} format the format to be used for data export
     */
    exportData(format?: string): void {
        const params: any = this.getParams();
        if (format == "pdf")
            params['exportPDF'] = "1";
        else if (format == "csv")
            params['exportCSV'] = "1";
        else
            params['print'] = "1";

        params['api_key'] = this.commonService.getApiKey();
        window.open(Config.printUrl + "?" + this.commonService.getQueryString(params), "_blank");
    }

    /**
     * Click handler for delete button click in the access control list.
     *
     * Opens a confirmation dialog box asking for user's permission to delete
     *
     * @author Sukhdeep Singh
     * @param {string} uuid id of the access control to be deleted
     * @param {string} name name of the access control to be deleted
     */
    onDeleteBtnClick(uuid: string, name: string): void {
        this.deleteId = uuid;
        this.deleteName = name;
        jQuery('#modal_delete').modal('open');
    }

    /**
     * Uses the service to delete a role
     *
     * @author Sukhdeep Singh
     */
    deleteAccessControl(): void {
        this.subscriptions.push(this.commonService.makeRequest({
            "request": "role_delete",
            "id": this.deleteId
        }).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.deleteCallBack()
        ));
    }

    /**
     * Shows the Toast and calls `getAccessControls` function to update the access control list
     *
     * @author Sukhdeep Singh
     * @callback `deleteAccessControl`
     */
    deleteCallBack(): void {
        if (!this.httpDataPayload || this.httpDataPayload.error) {
            this.handleError(this.httpDataPayload);
        } else {
            M.toast({
                html: "Role is deleted",
                displayLength: Config.messageIntervalShort
            });
            this.deleteId = null;
            this.deleteName = "";
            this.bindAccessControls();
        }
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {object} data data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }

    /**
     * Get role details
     *
     * @author Sukhdeep Singh
     * @param {string} uuid the role id
     */
    getAccessControl(uuid?: string): void {
        this.accessControl.id = "";
        this.accessControl.name = "";
        this.accessControl.active = "1";
        this.selectAllRead = this.selectAllWrite = this.selectAllDelete = this.selectAllExport = false;

        if (uuid) {
            this.progressService.show();
            this.subscriptions.push(this.commonService.makeRequest({
                "request": "role_details",
                "id": uuid
            }).subscribe(
                httpDataPayload => this.httpDataPayload = httpDataPayload,
                error => this.handleHttpError(error),
                () => this.bindAccessControl()
            ));
        } else {
            this.accessControlForm.reset();
            this.resetRoleModules();
            jQuery('#modal_access_details').modal('open');
        }
    }

    /**
     * Receives the role details data and filters it
     *
     * @author Sukhdeep Singh
     * @callback `getAccessControl`
     */
    bindAccessControl(): void {
        if (this.httpDataPayload && this.httpDataPayload.record) {
            this.accessControl = this.httpDataPayload.record;
            let roleModules: RoleModule[] = this.accessControl.role_modules;
            this.roleModules = [];
            if (roleModules && roleModules.length > 0) {
                for (let i = 0, len: number = roleModules.length; i < len; i++) {
                    this.roleModules.push(new RoleModule(roleModules[i]));
                }
            }
            this.rebuildForm();
            this.progressService.hide();
            jQuery('#modal_access_details').modal('open');
        } else
            this.handleError(this.httpDataPayload);
    }

    /**
     * Saves role details.
     *
     * Role Module data is retrieved from the form and added to the request
     *
     * @author Sukhdeep Singh
     */
    save(): void {
        this.progressService.show();
        const accessControlFormData = this.accessControlForm.value;
        const params = Object.assign({}, this.accessControl, accessControlFormData);
        params["role_modules"] = accessControlFormData.role_modules.map((roleModule: RoleModule) => Object.assign({}, roleModule));
        params['request'] = !this.accessControl.id ? "role_add" : "role_edit";

        this.subscriptions.push(this.commonService.makeRequest(params).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => {
                if (this.httpDataPayload && this.httpDataPayload.record) {
                    M.toast({
                        html: "Role saved",
                        displayLength: Config.messageIntervalShort
                    });
                    this.progressService.hide();
                    this.getAccessControls();
                } else
                    this.handleError(this.httpDataPayload);
            }
        ));
    }

    /**
     * Toggles the master select all checkbox depending upon if all the child checkboxes and checked or not
     *
     * @author Sukhdeep Singh
     * @param {Array<RoleModule>} roleModules array of role modules representing the data for each module
     */
    checkAllSelect(roleModules: Array<RoleModule>): void {
        this.accessControlForm.patchValue({
            "select_all_read": this.isAllSelected(roleModules, "r"),
            "select_all_write": this.isAllSelected(roleModules, "w"),
            "select_all_delete": this.isAllSelected(roleModules, "d"),
            "select_all_export": this.isAllSelected(roleModules, "x")
        }, {onlySelf: true, emitEvent: false});
    }

    /**
     * Checks if all priviledges are selected for a module
     *
     * @author Sukhdeep Singh
     * @param {Array<RoleModule>} roleModules array of role modules representing the data for each module
     * @param {string} key the privilege to check (read/write)
     */
    isAllSelected(roleModules: Array<RoleModule>, key: string): boolean {
        for (let i = 0, len: number = roleModules.length; i < len; i++) {
            if (!roleModules[i][key])
                return false;
        }
        return true;
    }

    /**
     * Click handler for Select All switch in role details modal.
     *
     * Changes all the child switches depending upon the master Select All switch for a particular permission.
     *
     * @author Sukhdeep Singh
     * @param {boolean} value the value to change the permission to
     * @param {string} permission the name of the permission to change
     */
    toggleAllPermissions(value: boolean, permission: string): void {
        const roleModules = this.accessControlForm.value['role_modules'];
        for (let i = 0, len: number = roleModules.length; i < len; i++) {
            roleModules[i][permission] = value;
        }
        this.accessControlForm.patchValue({"role_modules": roleModules}, {onlySelf: true, emitEvent: false});
    }

    /**
     * Resets the role module array and fills in necessary values when adding a new role
     *
     * @author Sukhdeep Singh
     */
    resetRoleModules(): void {
        this.roleModules = [];
        for (let i = 0, len: number = this.modules.length; i < len; i++) {
            this.roleModules.push(new RoleModule({
                module: this.modules[i]['name'],
                module_id: this.modules[i]['id']
            }));
        }
        this.rebuildForm();
    }

    /**
     * Regenerates the role module form control and adds it to the accessControlForm object
     *
     * @author Sukhdeep Singh
     */
    rebuildForm(): void {
        //create a form group for all the RoleModule objects in roleModules array. This generates form group for each RoleModule object which contains a FormControl for each individual object property
        const roleModuleFormGroups = this.roleModules.map(roleModule => this.formBuilder.group(roleModule));
        //add the newly created role module form group as a control in form of a formArray on the accessControlForm
        this.accessControlForm.setControl("role_modules", this.formBuilder.array(roleModuleFormGroups));
        //subscribe for the changes on the entire role_modules form array, anytime a value is changed in role modules form array, we need to check for select all checkbox
        this.subscriptions.push(this.accessControlForm.get("role_modules").valueChanges.subscribe((roleModules: Array<RoleModule>) => {
            this.checkAllSelect(roleModules);
        }));
        //patch the value for name form control
        this.accessControlForm.patchValue({name: this.accessControl.name}, {onlySelf: true, emitEvent: false});
        //perform a check on select all checkboxes
        this.checkAllSelect(this.accessControlForm.value['role_modules']);
    }

    /**
     * Getter method required for the ngFor form control that is displaying the role_module form array
     *
     * @returns {FormArray} role_modules control of the accessControlForm in form of FormArray
     */
    get role_modules(): FormArray {
        return this.accessControlForm.get('role_modules') as FormArray;
    }
}