import { NavigationEnd } from '@angular/router';
import { ReplaySubject } from "rxjs";

export class RouterStub {
    public url;
    private navEvents = new ReplaySubject();
    public readonly events = this.navEvents.asObservable();

    navigate(url: string) {
        this.url = url;
        this.triggerNavEvents(url);
    }

    navigateByUrl(url: string) {
        this.url = url;
        this.triggerNavEvents(url);
    }

    parseUrl(url: string) {
        return null;
    }

    triggerNavEvents(url) {
        this.navEvents.next(new NavigationEnd(0, url, null));
    }
}