export class ApiLog {
	id: number;
	app_id: number;
	request: string;
	content_type: string;
	host_name: string;
	user_agent: string;
	section_url: string;
	request_method: string;
	ip: string;
	access_time: string;
	api_file_name: string;
	user_id: number;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.app_id = params.app_id || null;
		this.request = params.request || null;
		this.content_type = params.content_type || null;
		this.host_name = params.host_name || null;
		this.user_agent = params.user_agent || null;
		this.section_url = params.section_url || null;
		this.request_method = params.request_method || null;
		this.ip = params.ip || null;
		this.access_time = params.access_time || null;
		this.api_file_name = params.api_file_name || null;
		this.user_id = params.user_id || null;
	}
}