import { DebugElement } from '@angular/core';
import { tick, ComponentFixture } from '@angular/core/testing';
import { HttpErrorResponse } from '@angular/common/http';
import { Records } from '../shared';

export * from './async-observable-helpers';
export * from './activated-route-stub';
export * from './jasmine-matchers';
export * from './router-link-directive-stub';
export * from './router-spy';
export * from './router-stub';

///// Short utilities /////

export function generateErrorHttpResponse() {
    return new HttpErrorResponse({
        error: "500 Internal Server Error",
        status: 500,
        statusText: "Internal Server Error"
    });
}

export function generateRecords(): Records {
    return {
        records: [
            {
                record: {
                    "id": null,
                    "name": "",
                    "label": ""
                }
            },
            {
                record: {
                    "id": 1,
                    "name": "admin",
                    "label": "admin"
                }
            },
            {
                record: {
                    "id": 2,
                    "name": "guest",
                    "label": "guest"
                }
            }
        ]
    };
}

export function generateFormattedDropdownData() {
    return [{ "id": null, "name": "", "label": "" }, { "id": 1, "name": "admin", "label": "admin" }, { "id": 2, "name": "guest", "label": "guest" }];
}

/** Wait a tick, then detect changes */
export function advance(f: ComponentFixture<any>): void {
    tick();
    f.detectChanges();
}

/**
 * Create custom DOM event the old fashioned way
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Event/initEvent
 * Although officially deprecated, some browsers (phantom) don't accept the preferred "new Event(eventName)"
 */
export function newEvent(eventName: string, bubbles = false, cancelable = false) {
    let evt = document.createEvent('CustomEvent');  // MUST be 'CustomEvent'
    evt.initCustomEvent(eventName, bubbles, cancelable, null);
    return evt;
}

// See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
/** Button events to pass to `DebugElement.triggerEventHandler` for RouterLink event handler */
export const ButtonClickEvents = {
    left: { button: 0 },
    right: { button: 2 }
};

/** Simulate element click. Defaults to mouse left-button click event. */
export function click(el: DebugElement | HTMLElement, eventObj: any = ButtonClickEvents.left): void {
    if (el instanceof HTMLElement) {
        el.click();
    } else {
        el.triggerEventHandler('click', eventObj);
    }
}
