export class SystemOption {
    company_name: string;
    company_email: string;
    smtp_email: string;
    smtp_email_password: string;

    constructor(params: any = {}) {
        this.company_name = params.company_name || null;
        this.company_email = params.company_email || null;
        this.smtp_email = params.smtp_email || null;
        this.smtp_email_password = params.smtp_email_password || null;
    }
}