export class Session {
	id: number;
	session_key: string;
	user_id: number;
	login_time: string;
	expire_time: string;
	ip: string;
	session_hash: string;
	app_id: number;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.session_key = params.session_key || null;
		this.user_id = params.user_id || null;
		this.login_time = params.login_time || null;
		this.expire_time = params.expire_time || null;
		this.ip = params.ip || null;
		this.session_hash = params.session_hash || null;
		this.app_id = params.app_id || null;
	}
}