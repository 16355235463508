import { Component, OnInit, AfterViewInit } from '@angular/core';

import { CommonService, DataRefreshService, Config } from '../shared/index';

@Component({
    selector: 'activity-log',
    templateUrl: './activity-log.component.html'
})
export class ActivityLogComponent implements OnInit, AfterViewInit {
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};

    constructor(private commonService: CommonService, private dataRefreshService: DataRefreshService) {
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
    }

    ngAfterViewInit() {
        this.dataRefreshService.refreshData(Config.activityLog);
    }
}