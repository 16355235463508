import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class SpinnerService {
    constructor() {
        this.notifySpinnerStatus = new EventEmitter<boolean>();
    }

    /**
     * This service sends `notifySpinnerStatus` as an input to whoever is listening to it in any component.
     *
     * `notifySpinnerStatus` is an event object that contains the Spinner status.
     *
     * This is like a pipeline of data flowing from this service to the listening component.
     *
     * @author Sukhdeep Singh
     */
    @Output() readonly notifySpinnerStatus: EventEmitter<boolean>;

    /**
     * Show the loading spinner.
     *
     * Sends the new spinner data object to show loading spinner in the `notifySpinnerStatus` Output pipeline.
     *
     * At the other end of the pipeline is the listening component waiting for incoming Spinner data object.
     *
     * @author Sukhdeep Singh
     */
    show() {
        this.notifySpinnerStatus.emit(true);
    }

    /**
     * Hide the loading spinner.
     *
     * Sends the new spinner data object to hide loading spinner in the `notifySpinnerStatus` Output pipeline.
     *
     * At the other end of the pipeline is the listening component waiting for incoming Spinner data object.
     *
     * @author Sukhdeep Singh
     */
    hide() {
        this.notifySpinnerStatus.emit(false);
    }
}