export class User {
	id: number;
	uuid: string;
	username: string;
	password: string;
	first_name: string;
	last_name: string;
	email: string;
	active: boolean;
	role_id: number;
	role: string;
	created_date: string;
	last_login_time: string;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.uuid = params.uuid || null;
		this.username = params.username || null;
		this.password = params.password || null;
		this.first_name = params.first_name || null;
		this.last_name = params.last_name || null;
		this.email = params.email || null;
		this.active = !(params.active === false || params.active == "0");
		this.role_id = params.role_id || null;
		this.role = params.role || null;
		this.created_date = params.created_date || null;
		this.last_login_time = params.last_login_time || null;
	}
}