import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CommonService, Config, DialogService, ProgressService, SystemOption } from '../shared/index';

@Component({
    selector: 'company-settings',
    templateUrl: './company-settings.component.html'
})
export class CompanySettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    companySettingsForm: FormGroup;
    output: any = null;
    subscriptions: Subscription[] = [];
    company: SystemOption;
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};

    constructor(private progressService: ProgressService, private router: Router, private commonService: CommonService, public dialogService: DialogService, private formBuilder: FormBuilder) {
        this.company = new SystemOption();
        this.createForm();
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
        this.getCompanySettings();
    }

    ngAfterViewInit() {
        this.commonService.initMaterialElements();
    }

    /**
     * Checks if there are any unsaved changes when the user tries to navigate away from current section
     *
     * @author Sukhdeep Singh
     * @return Promise or boolean : value indicating the user's decision of whether to discard changes or not
     */
    canDeactivate(): Promise<boolean> | boolean {
        let dataUnchanged: boolean = true;

        //if the user doesn't have write permissions to this section or it's an addMode then let the navigation continue
        if (Config.skipCanDeactivateGuard || this.accessControlData.w != '1')
            return true;

        const companySettingsFormData = this.companySettingsForm.value;
        // Check if the data has changed
        for (let key in this.company) {
            if (companySettingsFormData[key] != this.company[key]) {
                dataUnchanged = false;
                break;
            }
        }

        //Allow synchronous navigation (`true`) if the data has't changed
        if (dataUnchanged) {
            return true;
        }

        // Otherwise ask the user with the dialog service and return its
        // promise which resolves to true or false when the user decides
        return this.dialogService.confirm();
    }

    /**
     * Creates the reactive form object and instantiates all the form fields
     *
     * @author Sukhdeep Singh
     */
    createForm() {
        this.companySettingsForm = this.formBuilder.group({
            company_name: new FormControl(this.company.company_name, [Validators.required, Validators.maxLength(255)]),
            company_email: new FormControl(this.company.company_email, [Validators.required, Validators.maxLength(255), Validators.email]),
            smtp_email: new FormControl(this.company.smtp_email, [Validators.required, Validators.maxLength(255), Validators.email]),
            smtp_email_password: new FormControl(this.company.smtp_email_password, [Validators.required, Validators.maxLength(80)])
        });
    }

    /**
     * Get company settings
     *
     * @author Sukhdeep Singh
     */
    getCompanySettings(): void {
        this.progressService.show();
        this.subscriptions.push(this.commonService.makeRequest({"request": "system_option_details"}).subscribe(
            output => this.output = output,
            error => this.handleHttpError(error),
            () => this.bindCompanySettings()
        ));
    }

    /**
     * getCompanySettings() function callback
     *
     * @author Sukhdeep Singh
     * @callback CompanySettingsComponent~getCompanySettings
     */
    bindCompanySettings(): void {
        let data = this.output.record;
        if (data) {
            this.company = new SystemOption(data);
            this.companySettingsForm.patchValue(this.company);
            this.progressService.hide();
        } else {
            this.handleError(this.output);
        }
    }

    /**
     * save company settings
     *
     * @author Sukhdeep Singh
     */
    save(): void {
        this.progressService.show();
        const params = this.companySettingsForm.value;
        params['request'] = "system_option_edit";

        this.subscriptions.push(this.commonService.makeRequest(params).subscribe(
            output => this.output = output,
            error => this.handleHttpError(error),
            () => this.saveCallBack()
        ));
    }

    /**
     * save() function callback
     *
     * @author Sukhdeep Singh
     * @callback CompanySettingsComponent~save
     */
    saveCallBack(): void {
        if (this.output && this.output.record) {
            M.toast({
                html: "Settings updated",
                displayLength: Config.messageIntervalShort
            });
            this.bindCompanySettings();
        } else {
            this.handleError(this.output);
        }
    }


    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error: http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {object} data: data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }
}