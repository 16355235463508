import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class ProgressService {
    constructor() {
        this.notifyProgressStatus = new EventEmitter<boolean>();
    }

    /**
     * This service sends `notifyProgressStatus` as an input to whoever is listening to it in any component.
     *
     * `notifyProgressStatus` is an event object that contains the Progress status.
     *
     * This is like a pipeline of data flowing from this service to the listening component.
     *
     * @author Sukhdeep Singh
     */
    @Output() readonly notifyProgressStatus: EventEmitter<boolean>;

    /**
     * Show the progress bar.
     *
     * Sends the new progress data object to show progress bar in the `notifyProgressStatus` Output pipeline.
     *
     * At the other end of the pipeline is the listening component waiting for incoming Progress data object.
     *
     * @author Sukhdeep Singh
     */
    show() {
        this.notifyProgressStatus.emit(true);
    }

    /**
     * Hide the progress bar.
     *
     * Sends the new progress data object to hide progress bar in the `notifyProgressStatus` Output pipeline.
     *
     * At the other end of the pipeline is the listening component waiting for incoming Progress data object.
     *
     * @author Sukhdeep Singh
     */
    hide() {
        this.notifyProgressStatus.emit(false);
    }
}