import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';

import { CommonService, Pager, PagesOptions } from '../index';
import { Subscription } from 'rxjs';
import { FormControl } from "@angular/forms";

@Component({
    selector: 'pager',
    templateUrl: './pager.component.html'
})
export class PagerComponent implements OnInit, OnDestroy {
    //Input - paginationArray is received from feature components who are using PagerComponent as child component
    @Input() objPager: Pager;
    //Output - pageChange is sent to feature components who are using PagerComponent as child component
    @Output() readonly pagerChange: EventEmitter<boolean>;

    pageNumberFormControl: FormControl;
    rowsPerPageFormControl: FormControl;
    fromCount: number = 0;
    toCount: number = 0;
    rowsPerPageData: any[] = [];
    subscriptions: Subscription[] = [];

    constructor(public commonService: CommonService) {
        this.objPager = new Pager();
        this.pagerChange = new EventEmitter<boolean>();
        this.pageNumberFormControl = new FormControl();
        this.rowsPerPageFormControl = new FormControl();
    }

    ngOnInit() {
        this.subscriptions.push(this.commonService.pagerUpdates.subscribe(() => this.calculateResults()));
        this.subscriptions.push(this.pageNumberFormControl.valueChanges.subscribe((value) => {
            this.objPager.pageNum = value;
            this.onPageNumberChange();
        }));

        this.subscriptions.push(this.rowsPerPageFormControl.valueChanges.subscribe((value) => {
            this.objPager.rowsPerPage = value;
            this.onRowsPerPageChange();
        }));
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++)
            this.subscriptions[i].unsubscribe();
    }

    /**
     * Calculate the results range (from - to)
     *
     * @author Sukhdeep Singh
     */
    calculateResults(): void {
        this.pageNumberFormControl.setValue(this.objPager.pageNum, {emitEvent: false, onlySelf: true});
        this.rowsPerPageFormControl.setValue(this.objPager.rowsPerPage, {emitEvent: false, onlySelf: true});

        this.toCount = parseInt(<string>this.objPager.pageNum) * parseInt(<string>this.objPager.rowsPerPage);
        this.fromCount = (this.toCount - parseInt(<string>this.objPager.rowsPerPage)) + 1;
        //if the number of results are lesser than toCount or rowsPerPage, set the toCount to resultCount
        if (this.toCount > this.objPager.resultCount || this.objPager.rowsPerPage > this.objPager.resultCount)
            this.toCount = this.objPager.resultCount;

        const tempRowsPerPageData: PagesOptions[] = [
            {label: 50, id: 50}
        ];

        if (this.objPager.resultCount > 50) {
            tempRowsPerPageData.push({label: 100, id: 100});
            if (this.objPager.resultCount > 100) {
                tempRowsPerPageData.push({label: 200, id: 200});
                if (this.objPager.resultCount > 200) {
                    tempRowsPerPageData.push({label: 500, id: 500});
                    if (this.objPager.resultCount > 500) {
                        tempRowsPerPageData.push({label: 1000, id: 1000});
                    }
                }
            }
        }

        this.rowsPerPageData = tempRowsPerPageData;
    }

    /**
     * Takes action when page number is changed and emits pagerChange event if necessary
     *
     * Emitted event is received by feature components who are using PagerComponent as child component
     *
     * @author Sukhdeep Singh
     * @param {string} page name of the page to go to (first, last, previous, next)
     */
    onPageNumberChange(page: string = ""): void {
        if (this.commonService.onPageChange(page, this.objPager)) {
            this.pagerChange.next(true);
            this.calculateResults();
        }
    }

    /**
     * Updates the pager and emits the pagerChange event.
     *
     * Emitted event is received by feature components who are using PagerComponent as child component
     *
     * @author Sukhdeep Singh
     */
    onRowsPerPageChange(): void {
        this.commonService.updatePager(this.objPager);
        this.pagerChange.next(true);
        this.calculateResults();
    }

    trackByOptions(id: number, option: any): number {
        return option.id;
    }
}
