import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from "@angular/forms";
import { Subscription, ReplaySubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Config, ProgressService, CommonService, User, Pager } from '../shared/index';


@Component({
    selector: 'users',
    templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
    objPager: Pager;
    searchTermFormControl: FormControl;
    roleIdFormControl: FormControl;
    subscriptions: Subscription[] = [];
    users: ReplaySubject<User[]>;
    httpDataPayload: any = {};
    orderBy: string = "id";
    orderByDir: string = "asc";
    noResults: boolean = false;
    roles: Array<any> = [];
    deleteName: string = "";
    deleteId: string = null;
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};

    constructor(private progressService: ProgressService, private router: Router, private commonService: CommonService) {
        this.objPager = new Pager({pagerTitle: "user"});
        this.users = new ReplaySubject<User[]>();
        this.searchTermFormControl = new FormControl();
        this.roleIdFormControl = new FormControl();
    }


    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
        if (this.searchTermFormControl.value)
            sessionStorage.setItem("users_search_term", this.searchTermFormControl.value);
        else
            sessionStorage.removeItem("users_search_term");

        if (this.roleIdFormControl.value && this.roleIdFormControl.value.length != 0)
            sessionStorage.setItem("users_role_search_id", JSON.stringify(this.roleIdFormControl.value));
        else
            sessionStorage.removeItem("users_role_search_id");
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
        this.getDropDownData("roles", true);

        const searchTerm = sessionStorage.getItem("users_search_term");
        if (searchTerm)
            this.searchTermFormControl.setValue(searchTerm);

        const roleSearchId = sessionStorage.getItem("users_role_search_id");
        if (roleSearchId)
            this.roleIdFormControl.setValue(JSON.parse(roleSearchId), {emitEvent: false, onlySelf: true});

        this.getUsers();

        this.searchTermFormControl.valueChanges.pipe(
            debounceTime(Config.searchDebounceInterval),
            distinctUntilChanged(),
            switchMap((term: string) => {
                this.resetPager();
                return this.getDataListAsObservable();
            })).subscribe(httpDataPayload => {
            this.httpDataPayload = httpDataPayload;
            this.bindUsers();
        }, error => this.handleHttpError(error));

        this.subscriptions.push(this.roleIdFormControl.valueChanges.subscribe(
            value => this.getUsers()
        ));
    }

    ngAfterViewInit() {
        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Uses service to retrieve users list
     *
     * @author Sukhdeep Singh
     */
    getUsers(): void {
        this.subscriptions.push(this.getDataListAsObservable().subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.bindUsers()
        ));
    }

    /**
     * Uses the service to make http call and returns the Observable received from the service
     *
     * @author Sukhdeep Singh
     * @return Observable
     */
    getDataListAsObservable(): Observable<any> {
        this.progressService.show();
        return this.commonService.makeRequest(this.getParams());
    }

    /**
     * Parses the received data from server and assigns it to users array
     *
     * @author Sukhdeep Singh
     */
    bindUsers(): void {
        const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
        //if server sent some data, add it to the users array
        if (records) {
            const users: Array<User> = [];
            //last record in the response array is pager information
            const pagerData = records[records.length - 1];
            const totalRecords = pagerData && pagerData.page_data && parseInt(pagerData.page_data.result_count) ? parseInt(pagerData.page_data.result_count) : 0;
            if (totalRecords != this.objPager.resultCount) {
                this.objPager.resultCount = totalRecords;
                this.commonService.updatePager(this.objPager, true);
            }
            //check if search returned any data and set the boolean variable accordingly
            if (totalRecords > 0) {
                this.noResults = false;
                //remove the pager information from response array leaving it with only users
                records.pop();

                //loop through the response array and add values to users array
                for (let i = 0, len = records.length; i < len; i++)
                    users.push(new User(records[i].record));
            } else
                this.noResults = true;

            this.users.next(users);
            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
            this.noResults = true;
        }
    }

    /**
     * Get parameters when calling to get users list
     *
     * @author Sukhdeep Singh
     * @return {object} object containing all the parameters required for getting users list request
     */
    getParams(): any {
        return {
            "request": "user_list",
            "term": this.searchTermFormControl.value,
            "role_id": this.roleIdFormControl.value,
            "page_num": this.objPager.pageNum,
            "rows_per_page": this.objPager.rowsPerPage,
            "order_by": this.orderBy,
            "order_by_dir": this.orderByDir
        };
    }

    /**
     * Reset pager to initial values
     *
     * @author Sukhdeep Singh
     */
    resetPager(): void {
        this.commonService.resetPager(this.objPager);
    }

    /**
     * Clear search and retrieve a fresh list of users
     *
     * @author Sukhdeep Singh
     */
    clearSearch(): void {
        this.searchTermFormControl.setValue("", {onlySelf: true, emitEvent: false});
        this.roleIdFormControl.setValue([], {onlySelf: true, emitEvent: false});
        this.orderBy = "id";
        this.orderByDir = "asc";
        this.resetPager();
        this.getUsers();
    }


    /**
     * Sort the results depending on which header was clicked
     *
     * @author Sukhdeep Singh
     * @param {string} columnName name of the <th> column
     */
    doSort(columnName: string): void {
        this.orderBy = columnName;
        jQuery('.sort-header').removeClass(this.orderByDir);
        this.orderByDir = (this.orderByDir == "" || this.orderByDir == "desc") ? "asc" : "desc";
        jQuery('.sort-header.' + columnName).addClass(this.orderByDir);
        this.getUsers();
    }

    /**
     * This will open user details
     *
     * @author Sukhdeep Singh
     * @param {string} uuid user's id
     */
    openDetails(uuid: string): void {
        this.router.navigate(['/user', uuid]);
    }

    /**
     * Tracks users by their id's.
     *
     * Used by ngFor structural directive in the HTML template
     *
     * @author Sukhdeep Singh
     * @param {number} index The index of the item in a loop
     * @param {User} user user object containing data for a single row in the table
     * @return {number} Returns the id of the user which will be used to track users by ngFor directive
     */
    trackByUsers(index: number, user: User): number {
        return user.id;
    }

    /**
     * Open up new tab for exporting the data in chosen format
     *
     * @author Sukhdeep Singh
     * @param {string} format the format to be used for data export
     */
    exportData(format?: string): void {
        const params: any = this.getParams();
        if (format == "pdf")
            params['exportPDF'] = "1";
        else if (format == "csv")
            params['exportCSV'] = "1";
        else
            params['print'] = "1";

        params['api_key'] = this.commonService.getApiKey();
        window.open(Config.printUrl + "?" + this.commonService.getQueryString(params), "_blank");
    }

    /**
     * Click handler for delete button click in the user list. Opens a confirmation dialog box asking for user's permission to delete
     *
     * @author Sukhdeep Singh
     * @param {string} uuid id of the user to be deleted
     * @param {string} name name of the user to be deleted
     */
    onDeleteBtnClick(uuid: string, name: string): void {
        this.deleteId = uuid;
        this.deleteName = name;
        jQuery('#modal_delete').modal('open');
    }

    /**
     * Delete a user
     *
     * @author Sukhdeep Singh
     */
    deleteUser(): void {
        this.subscriptions.push(this.commonService.makeRequest({
            "request": "user_delete",
            "id": this.deleteId
        }).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => {
                if (!this.httpDataPayload || this.httpDataPayload.error) {
                    this.handleError(this.httpDataPayload);
                } else {
                    M.toast({
                        html: "User deleted",
                        displayLength: Config.messageIntervalShort
                    });
                    this.deleteId = null;
                    this.deleteName = "";
                    this.bindUsers();
                }
            }
        ));
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error: http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {object} data: data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }

    /**
     * Use the `commonService` to retrieve data for dropdowns, receive the results and pass it to `bindDropDownData` function
     *
     * @author Sukhdeep Singh
     * @param {string} table: name of the database table which holds the contents for dropdown
     * @param {boolean} noBlank : whether to have a blank record in the dropdown or not
     */
    getDropDownData(table: string, noBlank?: boolean): void {
        const params = {
            "table": table,
            "request": "dropdown"
        };

        if (noBlank)
            params['no_blank'] = "1";

        this.subscriptions.push(this.commonService.fetchDropDownData(params).subscribe(
            (data) => {
                if (table == Config.roles)
                    this.roles = data;
            },
            error => this.handleHttpError(error)
        ));
    }
}