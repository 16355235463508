import { NgModule } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as M from "materialize-css/dist/js/materialize";

import { LoginService } from './login.service';
import { ProgressService, Config, CommonService, AuthGuard } from '../shared/index';

@Component({
    selector: 'login-form',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    httpDataPayload: any = {};
    notLoggedIn: boolean = false;
    doLogout: string = "";
    subscriptions: Subscription[] = [];

    constructor(public authGuard: AuthGuard, private loginService: LoginService, private router: Router, private progressService: ProgressService, private route: ActivatedRoute, private commonService: CommonService, private formBuilder: FormBuilder) {
        this.createForm();
    }

    ngOnInit() {
        Config.skipCanDeactivateGuard = false;
        this.route.params.subscribe(params => {
            this.doLogout = params['logout'];
            //if the parameter "logout" equals to true, then log the user out
            if (this.doLogout == "true" && this.commonService.isLoggedIn()) {
                this.logout();
            } else {
                //check if the user is logged in or not
                if (this.commonService.isLoggedIn()) {
                    this.router.navigateByUrl(this.authGuard.redirectUrl ? this.authGuard.redirectUrl : '/dashboard');
                } else {
                    this.loginService.notLoggedIn();
                    this.notLoggedIn = true;
                    localStorage.clear();
                }
            }
        });
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    /**
     * Creates the reactive form object and instantiates all the form fields
     *
     * @author Sukhdeep Singh
     */
    createForm() {
        this.loginForm = this.formBuilder.group({
            username: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            password: new FormControl('', [Validators.required, Validators.maxLength(100)])
        });
    }


    /**
     * Handle the login functionality - use login service to do the login request and listen to the data returned by service
     *
     * @author Sukhdeep Singh
     */
    doLogin(): void {
        // If user name and password fields are set then only call LoginService otherwise show error message to fill required fields
        if (this.loginForm.valid) {
            this.progressService.show();
            const loginFormData = this.loginForm.value;
            this.subscriptions.push(this.loginService.login(loginFormData.username, loginFormData.password).subscribe(
                output => this.httpDataPayload = output,
                error => this.handleHttpError(error),
                () => this.callbackLogin()
            ));
        } else {
            M.toast({
                html: 'Please fill out all the required fields',
                displayLength: Config.messageIntervalNormal,
                classes: "error"
            });
        }
    }

    /**
     * Call back function for the doLogin function. Handles the response received from service and does necessary actions
     *
     * @author Sukhdeep Singh
     */
    callbackLogin(): void {
        this.progressService.hide();
        //if server returned successful login data
        if (this.httpDataPayload && this.httpDataPayload.record) {
            const data = this.httpDataPayload.record;

            //set the received data in HTML5 localStorage
            localStorage.setItem("expiry_time", data.expire_time);
            localStorage.setItem("login_time", data.login_time);
            localStorage.setItem("first_name", data.first_name);
            localStorage.setItem("last_name", data.last_name);
            localStorage.setItem("access_params", JSON.stringify(data.access_params));
            //call the loginSuccessful function of the login service to let other components know that user has successfully logged in
            this.loginService.loginSuccessful();

            //show the welcome toast
            if (data.first_name && data.last_name)
                M.toast({
                    html: "Welcome " + data.first_name + " " + data.last_name,
                    displayLength: Config.messageIntervalNormal
                });
            else
                M.toast({
                    html: "You are logged in as " + data.user_email,
                    displayLength: Config.messageIntervalNormal
                });

            const redirect = this.authGuard.redirectUrl ? this.authGuard.redirectUrl : '/dashboard';
            //navigate
            this.router.navigateByUrl(redirect);
        } else if (this.httpDataPayload && this.httpDataPayload.error && this.httpDataPayload.error.message) {
            //show the error message returned by server
            M.toast({
                html: this.httpDataPayload.error.message,
                displayLength: Config.messageIntervalNormal,
                classes: "error"
            });
        } else {
            //show generic error message
            M.toast({
                html: "Something went wrong. Please try again",
                displayLength: Config.messageIntervalNormal,
                classes: "error"
            });
        }
    }

    /**
     * Handles the logout functionality - use login service to do the logout request and listen to the data returned by service
     *
     * @author Sukhdeep Singh
     */
    logout(): void {
        this.subscriptions.push(this.loginService.logout().subscribe(
            output => this.httpDataPayload = output,
            error => this.handleHttpError(error),
            () => {
                if (this.httpDataPayload && this.httpDataPayload.message) {
                    //clear local storage
                    localStorage.clear();
                    //show the error message that logout was successful
                    M.toast({
                        html: "You have Logged out",
                        displayLength: Config.messageIntervalNormal
                    });
                    this.loginService.notLoggedIn();
                    this.notLoggedIn = true;
                }
            }
        ));
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }
}


@NgModule({
    imports: [
        RouterModule.forChild([
            {path: 'login', component: LoginComponent}
        ])
    ],
    exports: [
        RouterModule
    ]
})

export class LoginRoutingModule {
}
