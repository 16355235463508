export class RoleModule {
    id: number;
    uuid: string;
    role_id: number
    role: string;
    module_id: number
    module: string;
    module_name: string;
    r: boolean;
    w: boolean;
    d: boolean;
    x: boolean;
    active: boolean;

    constructor(params?: any) {
        this.id = params.id || null;
        this.uuid = params.uuid || null;
        this.role_id = params.role_id || null;
        this.role = params.role || null;
        this.module_id = params.module_id || null;
        this.module = params.module || null;
        this.module_name = params.module ? params.module.replace("_", " ") : null;
        this.r = params.r == "1";
        this.w = params.w == "1";
        this.d = params.d == "1";
        this.x = params.x == "1";
        this.active = !(params.active === false || params.active == "0");
    }
}