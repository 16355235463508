import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Config, CommonService, ProgressService, ColorScheme } from '../shared/index';

@Component({
    selector: 'color-scheme',
    templateUrl: './color-scheme.component.html'
})
export class ColorSchemeComponent implements OnInit, OnDestroy, AfterViewInit {
    colorSchemes: ColorScheme[] = [];
    subscriptions: Subscription[] = [];
    httpDataPayload: any = null;
    orderBy: string = "id";
    orderByDir: string = "asc";
    noResults: boolean = false;
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};
    selectedThemeId: FormControl;

    constructor(private progressService: ProgressService, private router: Router, private commonService: CommonService) {
        this.selectedThemeId = new FormControl();
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++)
            this.subscriptions[i].unsubscribe();
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
        this.subscriptions.push(this.selectedThemeId.valueChanges.subscribe(() => this.onThemeChange()));
        this.getColorSchemes();
    }

    ngAfterViewInit() {
        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Get color scheme list.
     *
     * @author Sukhdeep Singh
     */
    getColorSchemes(): void {
        this.progressService.show();
        this.subscriptions.push(this.commonService.makeRequest(this.getParams()).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => this.bindColorSchemes()
        ));
    }

    /**
     * Callback function for `getColorSchemes()`.
     *
     * Parses the received data from server.
     *
     * @author Sukhdeep Singh
     */
    bindColorSchemes(): void {
        const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
        let selectedThemeId = null;

        //if server sent some data, add it to the colorSchemes array
        if (records) {
            this.colorSchemes = [];
            //last record in the response array is pager information
            const pagerData = records[records.length - 1];
            const totalRecords = pagerData && pagerData.page_data && parseInt(pagerData.page_data.result_count) ? parseInt(pagerData.page_data.result_count) : 0;

            //check if search returned any data and set the boolean variable accordingly
            if (totalRecords > 0) {
                this.noResults = false;
                //remove the pager information from response array leaving it with only color scheme
                records.pop();

                for (let i = 0, len = records.length; i < len; i++) {
                    selectedThemeId = records[i].record.active_scheme == "1" ? records[i].record.uuid : selectedThemeId;
                    this.colorSchemes.push(new ColorScheme(records[i].record));
                }
                this.selectedThemeId.setValue(selectedThemeId, {onlySelf: true, emitEvent: false});
            } else
                this.noResults = true;

            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
            this.noResults = true;
        }
    }

    /**
     * Prepares parameters for color scheme list request.
     *
     * @author Sukhdeep Singh
     * @return object : object containing all the parameters required for making color scheme list request
     */
    getParams(): any {
        return {
            "request": "color_scheme_list",
            "page_num": 1,
            "rows_per_page": 100,
            "order_by": this.orderBy,
            "order_by_dir": this.orderByDir
        };
    }

    /**
     * Click handler for radio button click for theme selection
     *
     * @author Sukhdeep Singh
     */
    onThemeChange(): void {
        this.progressService.show();
        const params = this.getParams();
        params['request'] = "color_scheme_set_active";
        params['theme_id'] = this.selectedThemeId.value;

        this.subscriptions.push(this.commonService.makeRequest(params).subscribe(
            httpDataPayload => this.httpDataPayload = httpDataPayload,
            error => this.handleHttpError(error),
            () => {
                if (!this.httpDataPayload || this.httpDataPayload.error) {
                    this.handleError(this.httpDataPayload);
                } else {
                    jQuery("head #color-css-link").attr("href", "css/colors.css?" + new Date().getTime());
                    this.bindColorSchemes();
                }
            }
        ));
    }

    /**
     * Handles http request errors.
     *
     * @author Sukhdeep Singh
     * @param {object} error http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Handles error messages returned by server and take appropriate actions.
     *
     * @author Sukhdeep Singh
     * @param {object} data data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }
}