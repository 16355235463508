import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class DataRefreshService {
    constructor() {
        this.doDataRefresh = new EventEmitter<string>();
    }

    /**
     * This service sends `doDataRefresh` as an input to whoever is listening to it in any component.
     *
     * @author Sukhdeep Singh
     */
    @Output() readonly doDataRefresh: EventEmitter<string>;

    /**
     * Emits the `doDataRefresh` output event
     *
     * @author Sukhdeep Singh
     * @param {string} dataSectionName Name of the module who is supposed to refresh its data
     */
    refreshData(dataSectionName: string) {
        this.doDataRefresh.emit(dataSectionName);
    }
}