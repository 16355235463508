export class Config {

    static get apiUrl(): string {
        if (window.location.hostname === 'localhost') {
            return '/api/index.php';
        } else {
            return window.location.protocol + '//' + window.location.hostname + '/api/index.php';
        }
    }

    static get printUrl(): string {
        if (window.location.hostname === 'localhost') {
            return '/api/export.php';
        } else {
            return window.location.protocol + '//' + window.location.hostname + '/api/index.php';
        }
    }

    static get imagesPath(): string {
        return window.location.protocol + '//' + window.location.hostname + '/images';
    }

    static get maintenancePage(): string {
        return window.location.protocol + '//' + window.location.hostname + '/maintenance.php';
    }

    static get messageIntervalXShort(): number {
        return 1000;
    }

    static get messageIntervalShort(): number {
        return 2000;
    }

    static get messageIntervalNormal(): number {
        return 4000;
    }

    static get messageIntervalLong(): number {
        return 6000;
    }

    static get messageIntervalXLong(): number {
        return 8000;
    }

    static get messageIntervalXXLong(): number {
        return 10000;
    }

    static get searchDebounceInterval(): number {
        return 300;
    }

    static get maxTabletPortraitWidth(): number {
        return 993;
    }

    static get maxTabletLandscapeWidth(): number {
        return 1100;
    }

    static get max2In1LaptopWidth(): number {
        return 1620;
    }

    static skipCanDeactivateGuard: boolean = false;

    static get user(): string {
        return 'user';
    }

    static get roles(): string {
        return 'roles';
    }

    static get activityLog(): string {
        return 'activity-log';
    }

    static get nameValidationRegex(): string {
        return '^[a-zA-Z ,.\'-]*$';
    }

    static get passwordValidationRegex(): string {
        return '^(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$';
    }
}
