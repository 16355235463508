import { Component, OnInit, OnDestroy, AfterViewInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  UrlTree,
  UrlSegmentGroup,
  UrlSegment,
  PRIMARY_OUTLET
} from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../login/login.service';
import { SpinnerService, CommonService, Config, ProgressService, AuthGuard } from '../shared/index';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'ntx-web-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoggedIn: boolean = false;
  isSpinnerActive: boolean = false;
  isProgressActive: boolean = false;
  accessControl = {};
  httpDataPayload: any = [];
  subscriptions: Subscription[] = [];
  currentUserId: string = '';
  currentUserName: string = "";

  readonly animationEnd: string = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  mainPaddingLeft: string = "250px";
  sideNavWidth: string = "240px";
  navCollapserLeftPos: string = "200px";
  isSideBarOpen: boolean = false;
  activeSection: string = "";
  sessionCheckIntervalObj: any;
  readonly sessionExpireShowAlertTime: number = 300000;
  readonly sessionCheckTimeInterval: number = 60000;
  sessionExpireCountdownIntervalObj: any;
  sessionExpireCountdownText: string = "";
  isSessionExpireModalOpen: boolean = false;
  extendSessionDropdownData: Array<Object>;
  extendSessionFormControl: FormControl;

  constructor(private ref: ChangeDetectorRef, public authGuard: AuthGuard, private router: Router, private route: ActivatedRoute, private loginService: LoginService, private spinnerService: SpinnerService, private progressService: ProgressService, private commonService: CommonService) {
    this.extendSessionDropdownData = [{
      "id": 1,
      "label": "1 Hour"
    }, {
      "id": 3,
      "label": "3 Hours"
    }, {
      "id": 5,
      "label": "5 Hours"
    }, {
      "id": 10,
      "label": "10 Hours"
    }, {
      "id": 16,
      "label": "16 Hours"
    }, {
      "id": 24,
      "label": "24 Hours"
    }, {
      "id": 36,
      "label": "36 Hours"
    }, {
      "id": 48,
      "label": "48 Hours"
    }];
    this.extendSessionFormControl = new FormControl();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.isLoggedIn)
          this.setNavTitle(event.url);
      }
    });

    this.isLoggedIn = this.commonService.isLoggedIn();
    if (this.isLoggedIn) {
      this.setAccessControl();
      this.currentUserId = this.commonService.getCurrentUserUuid();
      this.currentUserName = this.commonService.getCurrentUserName();
    }

    // subscribe to loginService to show/hide UI components based on user's login status
    this.loginService.notifyLogin.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.handleLoginStatusUpdate();
      // tslint:disable-next-line:max-line-length
      //fire a change detection to make sure all states are consistent. Without this angular throws error in dev mode that `isLoggedIn` property value isn't consistent
      this.ref.detectChanges();
    });
    // subscribe to spinner service to show/hide the loading spinner
    this.spinnerService.notifySpinnerStatus.subscribe(isSpinnerActive => this.isSpinnerActive = isSpinnerActive);
    // subscribe to progress service to show/hide the linear progress bar
    this.progressService.notifyProgressStatus.subscribe(isProgressActive => this.isProgressActive = isProgressActive);

    if (window.innerWidth > Config.maxTabletPortraitWidth && window.innerWidth < Config.max2In1LaptopWidth) {
      this.mainPaddingLeft = '220px';
      this.sideNavWidth = '220px';
      this.navCollapserLeftPos = '180px';
    }

    this.isSideBarOpen = window.innerWidth > Config.maxTabletPortraitWidth;
    this.initTabsAfterAnimation('main');
  }

  ngAfterViewInit() {
    this.commonService.initMaterialElements();
    this.initSideNav();
    this.initSideNavCollapsible();

    // network connection offline event listener
    window.addEventListener('offline', this.offLineEventListener);

    // network connection online event listener
    window.addEventListener('online', this.onLineEventListener);
    this.checkSessionExpiryTime();
    this.sessionCheckIntervalObj = setInterval((): void => {
      this.checkSessionExpiryTime()
    }, this.sessionCheckTimeInterval);
  }

  ngOnDestroy() {
    //unsubscribe from event listeners
    this.spinnerService.notifySpinnerStatus.unsubscribe();
    this.progressService.notifyProgressStatus.unsubscribe();

    for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
      this.subscriptions[i].unsubscribe();
    }

    clearInterval(this.sessionCheckIntervalObj);
    if (this.sessionExpireCountdownIntervalObj)
      clearInterval(this.sessionExpireCountdownIntervalObj);

    window.removeEventListener("offline", this.offLineEventListener);
    window.removeEventListener("online", this.onLineEventListener);
  }

  /**
   * Handles login status change and updates the appropriate properties
   *
   * @author Sukhdeep Singh
   */
  handleLoginStatusUpdate() {
    this.currentUserName = "";
    this.currentUserId = "";
    //if the person is logged in
    if (this.isLoggedIn) {
      this.isSideBarOpen = window.innerWidth > Config.maxTabletPortraitWidth;
      this.changeClassAfterAnimation("main", "no-padding-left", false);
      //update the accessControl array
      this.setAccessControl();
      //Initialize the sideNav
      setTimeout((): void => {
        this.initSideNav();
        this.initSideNavCollapsible();
        this.commonService.initMaterialElements();
      }, 500);

      this.currentUserId = this.commonService.getCurrentUserUuid();
      this.currentUserName = this.commonService.getCurrentUserName();
    } else
      this.commonService.initMaterialElements();
  }

  initSideNav() {
    jQuery(".sidenav").sidenav();
  }

  initSideNavCollapsible() {
    const collapsibles = document.querySelectorAll('header ul.collapsible');
    if (collapsibles && collapsibles.length > 0)
      M.Collapsible.init(collapsibles);
  }

  /**
   * Set a title text to be displayed in the nav bar on mobile devices
   *
   * @author Sukhdeep Singh
   * @param {string} url the current url @example /system/access-control
   */
  setNavTitle(url: string): void {
    //parse current url and obtain the UrlTree
    const urlTree: UrlTree = this.router.parseUrl(url);
    let urlSegmentGroup: UrlSegmentGroup;

    if (urlTree) {
      //get all the children of the current root route
      urlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET];
    }

    if (urlSegmentGroup) {
      //array containing parts of the current active url
      const urlSegments: UrlSegment[] = urlSegmentGroup.segments;
      /**
       * Obtain the path name of the current url
       * @example if the url is /system/users, the urlSegment[0].path will return "system" and urlSegment[1].path will return "users"
       *          if the url is /user/1, then urlSegment[0].path will return "user" and urlSegment[1].path will return "1"
       */
      let section: string = urlSegments[0].path;

      //if the section is system (i.e system management), add the subsection name to it
      if (section == "system" && urlSegments[1].path) {
        section += "/" + urlSegments[1].path;
      }

      switch (section) {
        case "system/access-control":
          this.activeSection = "Access Control";
          break;
        case "system/session":
          this.activeSection = "User Sessions";
          break;
        case "system/users":
          this.activeSection = "Users";
          break;
        case "system/settings":
          this.activeSection = "Misc Settings";
          break;
        case "system/activity-log":
          this.activeSection = "Activity Log";
          break;
        case "dashboard":
          this.activeSection = "Dashboard";
          break;
        case "user":
          this.activeSection = "User Profile";
          break;
        case "system/api-log":
          this.activeSection = "API Access Log";
          break;
        case "system/color-scheme":
          this.activeSection = "System Themes";
          break;
        default:
          this.activeSection = "";
          break;
      }
    }
  }

  /**
   * Get access control from common service getAccessControlData function, set accessControl values
   *
   * @author Sukhdeep Singh
   */
  setAccessControl(): void {
    const accessParams = this.commonService.getAccessControlData();
    this.accessControl = {};
    for (let i: number = 0, len: number = accessParams.length; i < len; i++) {
      this.accessControl[accessParams[i]['module']] = (accessParams[i]['r'] == "1");
    }
  }

  /**
   * Handler for Logout button click event, unset authGuard redirectUrl variable and redirect to login page
   *
   * @author Sukhdeep Singh
   */
  doLogout(): void {
    this.authGuard.redirectUrl = '';
    this.router.navigate(['/login', {logout: 'true'}]);
  }

  /**
   * Handle http request errors
   *
   * @author Sukhdeep Singh
   * @param {HttpErrorResponse} error http error object
   */
  handleHttpError(error: any): void {
    this.commonService.handleHttpError(error);
  }

  /**
   * Toggles the sidebar visibility
   *
   * @author Sukhdeep Singh
   */
  toggleSideBar(): void {
    if (this.isSideBarOpen) {
      this.isSideBarOpen = false;
      this.changeCssAfterAnimation("-240px", ".sidenav", "left");
      this.changeCssAfterAnimation("0", "#nav-collapser", "left");
      this.changeClassAfterAnimation("main", "no-padding-left");
      this.initTabsAfterAnimation("main");
    } else {
      this.isSideBarOpen = true;
      jQuery('.sidenav').css('left', '0');
      this.changeCssAfterAnimation("0", ".sidenav", "left");
      jQuery('#nav-collapser').css('left', this.navCollapserLeftPos);
      this.changeCssAfterAnimation(this.navCollapserLeftPos, "#nav-collapser", "left");
      this.changeClassAfterAnimation("main", "no-padding-left", false);
      this.initTabsAfterAnimation("main");
    }
  }

  /**
   * Change provided css property of provided element when the browser is finished animating it
   *
   * @author Sukhdeep Singh
   * @param {string} value specify value for css property
   * @param {string} element target element selector
   * @param {string} property specify css property
   */
  changeCssAfterAnimation(value: string, element: string, property: string): void {
    const __this = this;
    jQuery(element).one(__this.animationEnd, (): void => {
      jQuery(element).css(property, value);
    });
  }

  /**
   * Change class name of provided element when the browser is finished animating it
   *
   * @author Sukhdeep Singh
   * @param {string} element target element selector
   * @param {string} className specify class name to be added or removed from the provided element
   * @param {boolean} addClass whether you want to add or remove class name from the provided element
   */
  changeClassAfterAnimation(element: string, className: string, addClass: boolean = true): void {
    const __this = this;
    jQuery(element).one(__this.animationEnd, (): void => {
      if (addClass)
        jQuery(element).addClass(className);
      else
        jQuery(element).removeClass(className);
    });
  }

  /**
   * Initialize tabs after the browser is finished animation
   *
   * @author Sukhdeep Singh
   * @param {string} element target element selector
   */
  initTabsAfterAnimation(element: string): void {
    const __this = this;
    jQuery(element).one(__this.animationEnd, (): void => {
      jQuery('ul.tabs').tabs();
    });
  }

  /**
   * Click handler for sidebar menu items.
   *
   * Closes the sidebar on mobile devices.
   *
   * @author Sukhdeep Singh
   */
  onSideBarItemClick() {
    if (window.innerWidth <= Config.maxTabletPortraitWidth) {
      jQuery(".sidenav").sidenav('close');
    }
  }

  /**
   * Checks for session expiry time and shows alert to user if the session is about to expire
   *
   * @author Sukhdeep Singh
   */
  checkSessionExpiryTime(): void {
    if (this.isLoggedIn) {
      const expiryTime: string = localStorage.getItem("expiry_time");

      if (expiryTime) {
        //session expire time in milliseconds
        const expireTimeInMilliseconds: number = new Date(expiryTime).getTime();
        //now time in milliseconds
        let nowTimeInMilliseconds: number = new Date().getTime();
        //difference ot session expire time and now time
        let sessionExpireDifference: number = expireTimeInMilliseconds - nowTimeInMilliseconds;

        if (!this.isSessionExpireModalOpen && sessionExpireDifference > 0 && sessionExpireDifference < this.sessionExpireShowAlertTime) {
          this.isSessionExpireModalOpen = true;
          const __this = this;

          //clear countdown timer when the modal closes. Must be defined before opening the modal
          jQuery('#modal_session_expiring_soon').modal({
            onCloseEnd: function () {
              clearInterval(__this.sessionExpireCountdownIntervalObj);
              __this.isSessionExpireModalOpen = false;
              __this.sessionExpireCountdownText = "";
            }
          });

          //open session expiring soon modal
          jQuery("#modal_session_expiring_soon").modal('open');

          //session expire countdown timer runs every second to show the time left till session expires
          this.sessionExpireCountdownIntervalObj = setInterval((): void => {
            nowTimeInMilliseconds = new Date().getTime();
            sessionExpireDifference = expireTimeInMilliseconds - nowTimeInMilliseconds;
            if (sessionExpireDifference > 0) {
              //calculate how many minutes remain till session expires
              const minutes: number = Math.floor((sessionExpireDifference % (1000 * 60 * 60)) / (1000 * 60));
              //calculate how many seconds remain under the minute till session expires
              const seconds: number = Math.floor((sessionExpireDifference % (1000 * 60)) / 1000);
              this.sessionExpireCountdownText = minutes + "m " + seconds + "s";
            } else {
              //session has expired. Close the modal.
              jQuery("#modal_session_expiring_soon").modal('close');
              this.isSessionExpireModalOpen = false;
              //clear the countdown timer
              clearInterval(this.sessionExpireCountdownIntervalObj);
              //set skipCanDeactivateGuard flag to true to steer clear the logout process
              Config.skipCanDeactivateGuard = true;
              //show the session expired message
              M.toast({
                html: "Your session has expired, Please login again.",
                displayLength: Config.messageIntervalLong
              });
              //logout
              this.doLogout();
            }
          }, 1000);
        }
      }
    }
  }

  /**
   * Extends current user's session by selected number of hours
   *
   * @author Sukhdeep Singh
   */
  extendSession(): void {
    this.commonService.makeRequest({
      "request": "session_extend",
      "id": this.commonService.getSessionId(),
      "num_of_hours_to_extend": this.extendSessionFormControl.value
    }).subscribe(
        output => this.httpDataPayload = output,
        error => this.handleHttpError(error),
        () => {
          if (this.httpDataPayload && this.httpDataPayload.record) {
            localStorage.setItem("expiry_time", this.httpDataPayload.record.expire_time);
            M.toast({
              html: "Session duration extended by " + this.extendSessionFormControl.value + " hour(s)",
              displayLength: Config.messageIntervalNormal
            });
          } else {
            M.toast({
              html: "Unable to extend session duration",
              displayLength: Config.messageIntervalNormal
            });
          }
        }
    );
  }

  trackBySessionOptions(id: number, option: any): number {
    return option.id;
  }

  /**
   * Event listener for browser offline event
   */
  offLineEventListener(): void {
    M.toast({
      html: "Not connected. Please check your internet connection",
      displayLength: Config.messageIntervalXXLong,
      classes: "error"
    });
  }

  /**
   * Event listener for browser online event
   */
  onLineEventListener(): void {
    M.toast({
      html: "Internet connection established",
      displayLength: Config.messageIntervalNormal
    });
  }

}
