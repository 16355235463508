// export for convenience.
export { ActivatedRoute } from '@angular/router';

import { ReplaySubject } from 'rxjs';
import { convertToParamMap, ParamMap, Params } from '@angular/router';

/**
 * An ActivateRoute test double with a `paramMap` observable.
 * Use the `setParamMap()` method to add the next `paramMap` value.
 */
export class ActivatedRouteStub {
    // Use a ReplaySubject to share previous values with subscribers
    // and pump new values into the `paramMap` observable
    private subjectParamMap = new ReplaySubject<ParamMap>();
    private subjectParams = new ReplaySubject<Params>();

    constructor(initialParams?: Params) {
        this.setParamMap(initialParams);
    }

    /** The mock paramMap observable */
    readonly paramMap = this.subjectParamMap.asObservable();
    /** The mock param observable */
    readonly params = this.subjectParams.asObservable();

    /** Set the paramMap observables's next value */
    setParamMap(params?: Params) {
        this.subjectParamMap.next(convertToParamMap(params));
    };

    /** Set the paramMap observables's next value */
    setParams(params?: Params) {
        this.subjectParams.next(params);
    };
}
