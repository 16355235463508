import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription ,  ReplaySubject ,  Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Config, ProgressService, CommonService, Pager, Session } from '../shared/index';
import { FormControl } from "@angular/forms";

@Component({
    selector: 'session',
    templateUrl: './session.component.html'
})
export class SessionComponent implements OnInit, OnDestroy, AfterViewInit {
    objPager: Pager;
    subscriptions: Subscription[] = [];
    sessions: ReplaySubject<Session[]>;
    searchTermFormControl: FormControl;
    httpDataPayload: any = null;
    orderBy: string = "id";
    orderByDir: string = "desc";
    noResults: boolean = false;
    deleteName: string = "";
    deleteId: number = null;
    accessControlData = {"r": "", "w": "", "d": "", "x": ""};

    constructor(private progressService: ProgressService, private router: Router, private commonService: CommonService) {
        this.objPager = new Pager({pagerTitle: "session"});
        this.searchTermFormControl = new FormControl();
        this.sessions = new ReplaySubject<Session[]>();
    }


    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    ngOnInit() {
        this.accessControlData = this.commonService.getAccessControlData("system_management");
        this.getSessions();

        this.searchTermFormControl.valueChanges.pipe(
            debounceTime(Config.searchDebounceInterval),
            distinctUntilChanged(),
            switchMap((term: string) => {
                this.resetPager();
                return this.getDataListAsObservable();
            })).subscribe(httpDataPayload => {
            this.httpDataPayload = httpDataPayload;
            this.bindSessionsData();
        }, error => this.handleHttpError(error));
    }

    ngAfterViewInit() {
        //initialize material elements
        this.commonService.initMaterialElements();
    }

    /**
     * Get session list
     *
     * @author Sukhdeep Singh
     */
    getSessions(): void {
        this.subscriptions.push(this.getDataListAsObservable().subscribe(
            output => this.httpDataPayload = output,
            error => this.handleHttpError(error),
            () => this.bindSessionsData()
        ));
    }

    /**
     * Uses the service to make http call and returns the Observable received from the service
     *
     * @author Sukhdeep Singh
     * @return Observable
     */
    getDataListAsObservable(): Observable<any> {
        this.progressService.show();
        return this.commonService.makeRequest(this.getParams());
    }

    /**
     * getSessions() callback
     *
     * @author Sukhdeep Singh
     */
    bindSessionsData(): void {
        const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
        //if server sent some data, add it to the sessions array
        if (records) {
            const sessions: Array<Session> = [];
            //last record in the response array is pager information
            const pagerData = records[records.length - 1];
            const totalRecords = pagerData && pagerData.page_data && parseInt(pagerData.page_data.result_count) ? parseInt(pagerData.page_data.result_count) : 0;
            if (totalRecords != this.objPager.resultCount) {
                this.objPager.resultCount = totalRecords;
                this.commonService.updatePager(this.objPager, true);
            }
            //check if search returned any data and set the boolean variable accordingly
            if (totalRecords > 0) {
                //remove the pager information from response array leaving it with only sessions
                records.pop();
                this.noResults = false;
                //loop through the response array and add values to sessions array
                for (let i = 0, len = records.length; i < len; i++)
                    sessions.push(new Session(records[i].record));
            } else
                this.noResults = true;

            this.sessions.next(sessions);
            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
            this.noResults = true;
        }
    }

    /**
     * Get parameters when calling to get session list
     *
     * @author Sukhdeep Singh
     * @return {Object} object containing all the parameters required for getting session list request
     */
    getParams(): any {
        return {
            "request": "session_list",
            "term":this.searchTermFormControl.value,
            "page_num": this.objPager.pageNum,
            "rows_per_page": this.objPager.rowsPerPage,
            "order_by": this.orderBy,
            "order_by_dir": this.orderByDir
        };
    }

    /**
     * Reset pager to initial values
     *
     * @author Sukhdeep Singh
     */
    resetPager(): void {
        this.commonService.resetPager(this.objPager);
    }

    /**
     * Clear search and retrieve a fresh list of sessions
     *
     * @author Sukhdeep Singh
     */
    clearSearch(): void {
        this.searchTermFormControl.setValue("", {onlySelf: true, emitEvent: false});
        this.orderBy = "id";
        this.orderByDir = "desc";
        this.resetPager();
        this.getSessions();
    }


    /**
     * Sort the results depending on which header was clicked
     *
     * @author Sukhdeep Singh
     * @param {string} columnName name of the <th> column
     */
    doSort(columnName: string): void {
        this.orderBy = columnName;
        jQuery('.sort-header').removeClass(this.orderByDir);
        this.orderByDir = (this.orderByDir == "" || this.orderByDir == "desc") ? "asc" : "desc";
        jQuery('.sort-header.' + columnName).addClass(this.orderByDir);
        this.getSessions();
    }

    /**
     * Tracks sessions by their id's.
     *
     * Used by ngFor structural directive in the HTML template
     *
     * @author Sukhdeep Singh
     * @param {number} index The index of the item in a loop
     * @param {Session} session session object containing data for a single row in the table
     * @return {number} Returns the id of the session which will be used to track sessions by ngFor directive
     */
    trackBySessions(index: number, session: Session): number {
        return session.id;
    }


    /**
     * Open up new tab for exporting the data in chosen format
     *
     * @author Sukhdeep Singh
     * @param {string} format the format to be used for data export
     */
    exportData(format?: string): void {
        const params: any = this.getParams();
        if (format == "pdf")
            params['exportPDF'] = "1";
        else if (format == "csv")
            params['exportCSV'] = "1";
        else
            params['print'] = "1";

        params['api_key'] = this.commonService.getApiKey();
        window.open(Config.printUrl + "?" + this.commonService.getQueryString(params), "_blank");
    }

    /**
     * Click handler for delete button click in the session list. Opens a confirmation dialog box asking for user's permission to delete
     *
     * @author Sukhdeep Singh
     * @param {number} id id of the session to be deleted
     * @param {string} name name of the session to be deleted
     */
    onDeleteBtnClick(id: number, name: string): void {
        this.deleteId = id;
        this.deleteName = name + "'s session";
        jQuery('#modal_delete').modal('open');
    }

    /**
     * Delete a session
     *
     * @author Sukhdeep Singh
     */
    deleteSession(): void {
        this.subscriptions.push(this.commonService.makeRequest({
            "request": "session_delete",
            "id": this.deleteId
        }).subscribe(
            output => this.httpDataPayload = output,
            error => this.handleHttpError(error),
            () => this.deleteCallBack()
        ));
    }

    /**
     * Callback function for deleteSession()
     *
     * @author Sukhdeep Singh
     */
    deleteCallBack(): void {
        if (!this.httpDataPayload || this.httpDataPayload.error) {
            this.handleError(this.httpDataPayload);
        } else {
            M.toast({
                html: "Session deleted",
                displayLength: Config.messageIntervalShort
            });
            this.deleteId = null;
            this.deleteName = "";
            this.bindSessionsData();
        }
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error: http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {object} data: data returned by server
     */
    handleError(data): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }
}
