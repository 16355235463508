export class ActivityLog {
	id: number;
	activity: string;
	item_table: string;
	item_id: number;
	created_date: string;
	user_id: number;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.activity = params.activity || null;
		this.item_table = params.item_table || null;
		this.item_id = params.item_id || null;
		this.created_date = params.created_date || null;
		this.user_id = params.user_id || null;
	}
}