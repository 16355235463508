export class ColorScheme {
	id: number;
	uuid: string;
	name: string;
	body_bg_color: string;
	primary_color: string;
	primary_color_light: string;
	primary_color_x_light: string;
	primary_color_xx_light: string;
	primary_color_dark: string;
	accent_color: string;
	accent_color_dark: string;
	button_color: string;
	active_scheme: string;

	constructor(params: any = {}) {
		this.id = params.id || null;
		this.uuid = params.uuid || null;
		this.name = params.name || null;
		this.body_bg_color = params.body_bg_color || null;
		this.primary_color = params.primary_color || null;
		this.primary_color_light = params.primary_color_light || null;
		this.primary_color_x_light = params.primary_color_x_light || null;
		this.primary_color_xx_light = params.primary_color_xx_light || null;
		this.primary_color_dark = params.primary_color_dark || null;
		this.accent_color = params.accent_color || null;
		this.accent_color_dark = params.accent_color_dark || null;
		this.button_color = params.button_color || null;
		this.active_scheme = params.active_scheme || null;
	}
}