export class Pager {
    pageNum: number | string;
    rowsPerPage: number | string;
    pages: PagesOptions[];
    pagerTitle: string;
    resultCount: number;
    pluralTitle: boolean;
    totalPageCount: number;

    constructor(params: any = {}) {
        this.pageNum = params.pageNum || 1;
        this.rowsPerPage = params.rowsPerPage || 50;
        this.totalPageCount = params.totalPageCount || 0;
        this.pages = params.pages || [];
        this.pagerTitle = params.pagerTitle || "records";
        this.resultCount = params.resultCount || 0;
        this.pluralTitle = params.pluralTitle || false;
    }
}

export class PagesOptions {
    id: number;
    label: number;
}