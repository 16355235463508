import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { UserComponent, UserRoutingModule } from './user.component';

@NgModule({
    imports: [
        SharedModule,
        UserRoutingModule
    ],
    declarations: [
        UserComponent
    ]
})
export class UserModule { }
