import { Component, OnInit, OnDestroy, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from "@angular/forms";
import { Subscription, ReplaySubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ActivityLog, CommonService, Config, Pager, ProgressService, DataRefreshService } from '../index';


@Component({
    selector: 'activity-list',
    templateUrl: './activity-list.component.html'
})
export class ActivityListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    objPager: Pager;
    httpDataPayload: any = null;
    subscriptions: Subscription[] = [];
    activities: ReplaySubject<ActivityLog[]>;
    searchTermFormControl: FormControl;
    orderBy: string = "id";
    orderByDir: string = "desc";
    noResults: boolean = false;
    fromDate: FormControl;
    toDate: FormControl;

    @Input() params: any = {};

    //All parent feature components send accessPermission as an input to this shared module. Used for access control purposes
    @Input() accessPermission: string = "";

    constructor(private progressService: ProgressService, private commonService: CommonService, private dataRefreshService: DataRefreshService, private router: Router) {
        this.objPager = new Pager({pagerTitle: "activity", pluralTitle: true});
        this.searchTermFormControl = new FormControl();
        this.activities = new ReplaySubject<ActivityLog[]>();
        this.fromDate = new FormControl({
            value: null,
            disabled: true
        });
        this.toDate = new FormControl({
            value: null,
            disabled: true
        });
    }

    ngOnInit() {
        this.subscriptions.push(this.dataRefreshService.doDataRefresh.subscribe(sectionName => {
            if (sectionName == Config.activityLog)
                this.getActivity();
        }));

        this.searchTermFormControl.valueChanges.pipe(
            debounceTime(Config.searchDebounceInterval),
            distinctUntilChanged(),
            switchMap((term: string) => {
                this.resetPager();
                return this.getDataListAsObservable();
            })).subscribe(httpDataPayload => {
            this.httpDataPayload = httpDataPayload;
            this.bindActivity();
        }, error => this.handleHttpError(error));

        this.subscriptions.push(this.toDate.valueChanges.subscribe(() => {
            this.resetPager();
            this.getActivity();
        }));
        this.subscriptions.push(this.fromDate.valueChanges.subscribe(() => {
            this.resetPager();
            this.getActivity();
        }));
    }

    ngOnDestroy() {
        for (let i: number = 0, len: number = this.subscriptions.length; i < len; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }


    ngAfterViewInit() {
        //initialize material UI elements
        this.commonService.initMaterialElements();
    }

    /**
     * Angular's lifecycle hook for every time there is a change in any of the data-bound Input properties
     *
     * @author Sukhdeep Singh
     * @param {SimpleChanges} changes : A SimpleChanges object of current and previous property values
     */
    ngOnChanges(changes: SimpleChanges) {
        //if there are any changes in the params input variable, retrieve the activity log again
        if (changes.params && changes.params.currentValue) {
            setTimeout(() => {
                this.getActivity();
            }, 50);
        }
    }

    /**
     * Uses service to retrieve activity data
     *
     * @author Sukhdeep Singh
     */
    getActivity(): void {
        //if `item_table` is present in `params` object, then only retrieve data if `item_id` is available as well
        if (!this.params.item_table || (this.params.item_table && this.params.item_id)) {
            this.subscriptions.push(this.getDataListAsObservable().subscribe(
                output => this.httpDataPayload = output,
                error => this.handleHttpError(error),
                () => this.bindActivity()
            ));
        }
    }

    /**
     * Uses the service to make http call and returns the Observable received from the service
     *
     * @author Sukhdeep Singh
     * @return Observable
     */
    getDataListAsObservable(): Observable<any> {
        this.progressService.show();
        return this.commonService.makeRequest(this.getParams());
    }

    /**
     * Callback function to `getActivity`.
     *
     * Receives the data from server and assigns it to activities array.
     *
     * Sets some pager properties
     *
     * @author Sukhdeep Singh
     */
    bindActivity(): void {
        const records = this.httpDataPayload && this.httpDataPayload.records ? this.httpDataPayload.records : null;
        //if server sent some data, add it to the activities array
        if (records) {
            const activities: Array<ActivityLog> = [];
            //last record in the response array is pager information
            const pagerData = records[records.length - 1];

            const totalRecords = pagerData && pagerData.page_data && parseInt(pagerData.page_data.result_count) ? parseInt(pagerData.page_data.result_count) : 0;
            if (totalRecords != this.objPager.resultCount) {
                this.objPager.resultCount = totalRecords;
                this.commonService.updatePager(this.objPager, true);
            }
            //check if search returned any data and set the boolean variable accordingly
            if (totalRecords > 0) {
                this.noResults = false;
                //remove the pager information from response array leaving it with only activities
                records.pop();

                //loop through the response array and add values to activities array
                for (let i = 0, len = records.length; i < len; i++)
                    activities.push(new ActivityLog(records[i].record));
            } else
                this.noResults = true;

            this.activities.next(activities);
            this.progressService.hide();
        } else {
            this.handleError(this.httpDataPayload);
            this.noResults = true;
        }
    }

    /**
     * Create params for activity
     *
     * @author Sukhdeep Singh
     * @return {Object} object containing all the parameters required for activity list request
     */
    getParams(): any {
        return Object.assign({
            "request": "activity_log_list",
            "term": this.searchTermFormControl.value,
            "page_num": this.objPager.pageNum,
            "rows_per_page": this.objPager.rowsPerPage,
            "order_by": this.orderBy,
            "order_by_dir": this.orderByDir,
            "initiated_at_gt": this.commonService.getFormattedMomentDate(this.fromDate.value),
            "initiated_at_lt": this.commonService.getFormattedMomentDate(this.toDate.value)
        }, this.params);
    }

    /**
     * Reset pager to initial values
     *
     * @author Sukhdeep Singh
     */
    resetPager(): void {
        this.commonService.resetPager(this.objPager);
    }

    /**
     * Handle error messages returned by server and take appropriate actions
     *
     * @author Sukhdeep Singh
     * @param {Object} data data returned by server
     */
    handleError(data: any): void {
        //show the error message
        this.commonService.showErrorMessage(data);
        this.progressService.hide();

        if (this.commonService.isAccountSuspended(data)) { //check if account is suspended
            this.router.navigate(['/suspended']);
        } else if (this.commonService.isUnderMaintenance(data)) { //check if Application is under maintenance
            window.location.href = Config.maintenancePage;
        } else if (this.commonService.isSessionExpired(data)) { //check if the session is expired
            this.router.navigate(['/login']);
        }
    }

    /**
     * Handle http request errors
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error http error object
     */
    handleHttpError(error: any): void {
        this.commonService.handleHttpError(error);
        this.progressService.hide();
    }

    /**
     * Clear activity list search
     *
     * @author Sukhdeep Singh
     */
    clearSearch(): void {
        this.searchTermFormControl.setValue("", {onlySelf: true, emitEvent: false});
        this.fromDate.setValue(null, {emitEvent: false, onlySelf: true});
        this.toDate.setValue(null, {emitEvent: false, onlySelf: true});
        this.resetPager();
        this.getActivity();
    }

    /**
     * Tracks activities by their id's.
     *
     * Used by ngFor structural directive in the HTML template
     *
     * @author Sukhdeep Singh
     * @param {number} index The index of the item in a loop
     * @param {ActivityLog} activityLog activityLog object containing data for a single row in the table
     * @return {number} Returns the id of the activityLog which will be used to track activities by ngFor directive
     */
    trackByActivityLogs(index: number, activityLog: ActivityLog): number {
        return activityLog.id;
    }

    /**
     * Open up new tab for exporting the data in chosen format
     *
     * @author Sukhdeep Singh
     * @param {string} format the format to be used for data export
     */
    exportData(format?: string): void {
        const params: any = this.getParams();
        if (format == "pdf")
            params['exportPDF'] = "1";
        else if (format == "csv")
            params['exportCSV'] = "1";
        else
            params['print'] = "1";

        params['api_key'] = this.commonService.getApiKey();
        const url = Config.printUrl + "?" + this.commonService.getQueryString(params);
        window.open(url, "_blank");
    }

    /**
     * Sort the results depending on which header was clicked
     *
     * @author Sukhdeep Singh
     * @param {string} columnName name of the <th> column
     */
    doSort(columnName: string): void {
        this.orderBy = columnName;
        jQuery('.sort-header').removeClass(this.orderByDir);
        this.orderByDir = (this.orderByDir == "" || this.orderByDir == "desc") ? "asc" : "desc";
        jQuery('.sort-header.' + columnName).addClass(this.orderByDir);
        this.getActivity();
    }
}