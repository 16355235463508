import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthGuard} from '../shared';
import {HomeComponent} from './home.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard]}
        ])
    ],
    exports: [
        RouterModule
    ]
})

export class HomeRoutingModule {
}
