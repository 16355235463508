import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Config, Pager } from './index';
import * as M from "materialize-css/dist/js/materialize";
import * as jQuery from 'jquery';
import * as moment from 'moment';

export interface Record {
    record: object | Array<object> | Array<string>;
}

export interface Records {
    records: Record[];
}

@Injectable()
export class CommonService {
    protected readonly NETWORK_RETRY_ATTEMPTS: number = 3;
    pagerUpdates: Subject<boolean>;

    constructor(protected http: HttpClient) {
        this.pagerUpdates = new Subject<boolean>();
    }

    /**
     * Throws http error
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} error HttpErrorResponse object
     * @return {Observable} http error as an observable object
     */
    protected throwHttpError(error: HttpErrorResponse): Observable<never> {
        return observableThrowError(error || 'An Error Occurred');
    }

    /**
     * Returns HttpErrorResponse object for internet connection error
     *
     * @author Sukhdeep Singh
     * @return {HttpErrorResponse} HttpErrorResponse object containing network connection error
     */
    protected getNetworkErrorResponse(status: number = -10, statusText: string = "Not connected. Please check your internet connection"): HttpErrorResponse {
        return new HttpErrorResponse({
            status: status,
            statusText: statusText
        });
    }

    /**
     * Makes http call to retrieve data
     *
     * @author Sukhdeep Singh
     * @param {Object} params parameters to pass along with the request
     * @return {Observable} results returned by server as an observable object
     */
    makeRequest(params: Object): Observable<Records | Record | null | Object> {
        if (!navigator.onLine) {
            return this.throwHttpError(this.getNetworkErrorResponse());
        }
        params['api_key'] = this.getApiKey();
        const httpOptions = {
            withCredentials: true,
            headers: new HttpHeaders().set('Content-Type', 'application/json').set('Requested-With', 'NTX-WebApp')

        };

        return this.http.post<Records | Record>(Config.apiUrl, JSON.stringify(params), httpOptions).pipe(retry(this.NETWORK_RETRY_ATTEMPTS));
    }

    /**
     * Makes http call to upload a file
     *
     * @author Sukhdeep Singh
     * @param {FormData} formData A FormData object containing file and other parameters to pass with the request
     * @return {Observable} results returned by server as an observable object
     */
    uploadFile(formData: FormData): Observable<any> {
        if (!navigator.onLine) {
            return this.throwHttpError(this.getNetworkErrorResponse());
        }
        formData.append("api_key", this.getApiKey());
        const httpOptions = {
            headers: new HttpHeaders().set('Requested-With', 'NTX-WebApp'),
            reportProgress: true
        };
        const request = new HttpRequest("POST", Config.apiUrl, formData, httpOptions);
        return this.http.request<Records | Record>(request).pipe(retry(this.NETWORK_RETRY_ATTEMPTS));
    }

    /**
     * Checks if user is logged in
     *
     * @author Sukhdeep Singh
     * @return {boolean} whether the person has a valid login status or not
     */
    isLoggedIn(): boolean {
        if (!localStorage.getItem("expiry_time") || !Cookie.get('uid') || !Cookie.get('session_key') || !Cookie.get('api_key'))
            return false;

        if (new Date().getTime() >= new Date(localStorage.getItem("expiry_time")).getTime()) {
            localStorage.clear();
            Cookie.deleteAll();
            return false;
        }
        return true;
    }

    /**
     * Returns current user's id
     *
     * @author Sukhdeep Singh
     * @return {number} current user's id
     */
    getCurrentUserId(): number {
        const uid: string = Cookie.get('uid');
        return uid ? parseInt(uid) : null;
    }

    /**
     * Returns current user's id
     *
     * @author Sukhdeep Singh
     * @return {number} current user's id
     */
    getCurrentUserUuid(): string {
        return Cookie.get('uuid') || "";
    }

    /**
     * Returns current session'd ID
     *
     * @author Sukhdeep Singh
     * @return {string} session ID
     */
    getSessionId(): string {
        return Cookie.get('session_id');
    }

    /**
     * Checks if the current user is admin or not
     *
     * @author Sukhdeep Singh
     * @param {Object} data the user details data object
     * @returns {boolean} whether the current user is admin or not
     */
    isAdminUser(data: any): boolean {
        return data.role_id == "1" && data.role && data.role.toUpperCase().indexOf("ADMIN") !== -1;
    }

    /**
     * Returns app api key
     *
     * @author Sukhdeep Singh
     * @return {string} api key
     */
    getApiKey(): string {
        return Cookie.get('api_key');
    }

    /**
     * Returns current user's username
     *
     * @author Sukhdeep Singh
     * @return {string} current user's username
     */
    getCurrentUserName(): string {
        const userName = Cookie.get('user_name');
        return userName ? userName.replace("+", " ") : this.getCurrentUserFullName();
    }

    /**
     * Returns current user's full name
     *
     * @author Sukhdeep Singh
     * @return {string} current user's full name
     */
    getCurrentUserFullName(): string {
        const firstName: string = localStorage.getItem("first_name");
        const lastName: string = localStorage.getItem("last_name");
        return firstName && lastName ? firstName + " " + lastName : "";
    }

    /**
     * Returns current user's email address
     *
     * @author Sukhdeep Singh
     * @return {string} current user's email address
     */
    getCurrentUserEmail(): string {
        const userEmail: string = Cookie.get('user_email');
        return userEmail ? userEmail : "";
    }

    /**
     * Shows error message when an error returned after from a http request
     *
     * @author Sukhdeep Singh
     * @param {Object} data object containing data returned by server
     */
    showErrorMessage(data: any): void {
        if (data && data.error) {
            //check if it's a session expired error
            if (data.error.code == "103") {
                M.toast({
                    html: "Your session has expired or is invalid. Please login to continue",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
            } else if (data.error.message) {
                //if server sent any error message show it
                M.toast({
                    html: data.error.message,
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
            } else {
                //otherwise just show generic error message
                M.toast({
                    html: "Something went wrong. Server sent no data. Please try again",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
            }
        } else {
            //probably no data sent by server, show generic error message
            M.toast({
                html: "Something went wrong. Server sent no data. Please try again",
                displayLength: Config.messageIntervalXLong,
                classes: "error"
            });
        }
    }

    /**
     * Handles error returned by http request and show appropriate message
     *
     * @author Sukhdeep Singh
     * @param {HttpErrorResponse} httpErrorResponse http error object
     */
    handleHttpError(httpErrorResponse: HttpErrorResponse): void {
        switch (httpErrorResponse.status) {
            case 413:
                M.toast({
                    html: "Error : The selected file is too large. Please choose another file.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 403:
                M.toast({
                    html: "Error : You do not have access to this resource. Please try again.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 404:
                M.toast({
                    html: "Error : Requested resource does not exist or may have been deleted. Please try again.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 408:
                M.toast({
                    html: "Error : Request timed out. Please try again.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 503:
                M.toast({
                    html: "Error : Service temporarily unavailable. Please try again in few minutes.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 504:
                M.toast({
                    html: "Error : Request timed out. Please try again.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            case 200:
                M.toast({
                    html: "Error : Server sent incorrect data.",
                    displayLength: Config.messageIntervalXLong,
                    classes: "error"
                });
                break;

            default:
                if (httpErrorResponse.statusText)
                    M.toast({
                        html: "Error : " + httpErrorResponse.statusText,
                        displayLength: Config.messageIntervalXLong,
                        classes: "error"
                    });
                else
                    M.toast({
                        html: "Something went wrong. Please try again",
                        displayLength: Config.messageIntervalXLong,
                        classes: "error"
                    });
                break;
        }
    }

    /**
     * Checks if session is expired
     *
     * @author Sukhdeep Singh
     * @param {Object} data data returned by server
     * @return {boolean} whether the session is expired or not
     */
    isSessionExpired(data: any): boolean {
        if (data && data.error && data.error.code == "103") {
            //clear cookies and localStorage
            Cookie.deleteAll();
            localStorage.clear();
            Config.skipCanDeactivateGuard = true;
            return true;
        } else
            Config.skipCanDeactivateGuard = false;
        return false;
    }

    /**
     * Checks if this client's account is suspended
     *
     * @author Sukhdeep Singh
     * @param {Object} data data returned by server
     * @return {boolean} whether the client's account is suspended or not
     */
    isAccountSuspended(data: any): boolean {
        if (data && data.error && data.error.code == "106") {
            Cookie.deleteAll();
            localStorage.clear();
            Config.skipCanDeactivateGuard = true;
            return true;
        } else
            Config.skipCanDeactivateGuard = false;

        return false;
    }

    /**
     * Checks if the application is under maintenance
     *
     * @author Sukhdeep Singh
     * @param {Object} data data returned by server
     * @return {boolean} whether the application is under maintenance or not
     */
    isUnderMaintenance(data: any): boolean {
        if (data && data.error && data.error.code == "107") {
            Config.skipCanDeactivateGuard = true;
            return true;
        } else
            Config.skipCanDeactivateGuard = false;

        return false;
    }

    /**
     * Generates query string from an object
     *
     * @param {object} params the object containing data
     * @return {string} formatted query string
     */
    getQueryString(params: any): string {
        const queryStringArray = [];

        Object.entries(params).forEach(([key, value]) => {
            if (value != null && value != '')
                queryStringArray.push(`${key}=${value}`);
        });

        return queryStringArray.join("&");
    }

    /**
     * Initializes Material UI Elements
     *
     * @author Sukhdeep Singh
     */
    initMaterialElements(): void {
        const modals = document.querySelectorAll('main .modal');
        if (modals && modals.length > 0)
            M.Modal.init(modals, {
                onOpenStart: function () {
                    //add class no-scroll to main element to remove overflow ( for iPhone screen )
                    jQuery('main').addClass('no-scroll');
                },
                onCloseEnd: function () {
                    //remove class no-scroll to main element
                    jQuery('main').removeClass('no-scroll');
                }
            });

        const collapsibles = document.querySelectorAll('main ul.collapsible');
        if (collapsibles && collapsibles.length > 0)
            M.Collapsible.init(collapsibles);
    }

    /**
     * OnPagechange event for pager
     *
     * @author Sukhdeep Singh
     * @param {string} page name of the page to switch to
     * @param {Pager} objPager the pager object
     * @return {boolean} Whether to emit the event to the component to refresh the list
     */
    onPageChange(page: string, objPager: Pager): boolean {
        let emitEvent = false;
        switch (page) {
            case "first":
                //if not already on the first page, go to first page
                if (objPager.pageNum != 1) {
                    //set the current page number to 1
                    objPager.pageNum = 1;
                    //notify to execute callback function
                    emitEvent = true;
                }
                break;
            case "last":
                //if not already on the last page, go to last page
                if (objPager.pageNum != objPager.totalPageCount) {
                    //set the current page number to last page
                    objPager.pageNum = objPager.totalPageCount;
                    //notify to execute callback function
                    emitEvent = true;
                }
                break;
            case "next":
                //if not already on the last page, go to next page
                if (parseInt(<string>objPager.pageNum) + 1 <= objPager.totalPageCount) {
                    //add 1 to the current page number
                    objPager.pageNum = parseInt(<string>objPager.pageNum) + 1;
                    //notify to execute callback function
                    emitEvent = true;
                }
                break;
            case "previous":
                //if not already on the first page, go to previous page
                if (parseInt(<string>objPager.pageNum) - 1 > 0) {
                    //subtract 1 from the current page number
                    objPager.pageNum = parseInt(<string>objPager.pageNum) - 1;
                    //notify to execute callback function
                    emitEvent = true;
                }
                break;
            default:
                //notify to execute callback function
                emitEvent = true;
                break;
        }
        return emitEvent;
    }

    /**
     * Updates pager information when rows per page is updated
     *
     * @author Sukhdeep Singh
     * @param {Pager} objPager the pager object
     * @param {boolean} emitEvent Whether to emit the `pagerUpdates` event to pager
     */
    updatePager(objPager: Pager, emitEvent?: boolean): void {
        //calculate total number of pages
        objPager.totalPageCount = Math.ceil(objPager.resultCount / parseInt(<string>objPager.rowsPerPage));
        objPager.pages = [];

        //add values to the page dropdown
        for (let i = 1; i <= objPager.totalPageCount; i++) {
            objPager.pages.push({id: i, label: i});
        }
        //go to first page
        objPager.pageNum = 1;
        if (emitEvent)
            this.emitPagerUpdate();
    }

    /**
     * Emits pagerUpdates event to notify pager component that it needs to update its UI
     *
     * @author Sukhdeep Singh
     */
    emitPagerUpdate() {
        this.pagerUpdates.next(true);
    }

    /**
     * Resets pager object
     *
     * @author Sukhdeep Singh
     * @param {Pager} objPager the pager object
     */
    resetPager(objPager: Pager): void {
        objPager.pageNum = 1;
        objPager.rowsPerPage = 50;
        this.emitPagerUpdate();
    }

    /**
     * Formats the date according to `yyyy-mm-dd` format, usually needed by the material datepicker
     *
     * @author Sukhdeep Singh
     * @param {Date} dateObj the `Date` object to use when formatting the date.
     * @return {string} date in yyyy-mm-dd format
     */
    getFormattedDate(dateObj: Date = new Date()): string {
        //add prefix "0" to month number if month is lesser than 10
        const formattedMonth = (dateObj.getMonth() + 1) < 10 ? '0' + (dateObj.getMonth() + 1) : (dateObj.getMonth() + 1);
        //add prefix "0" to day number if day is lesser than 10
        const formattedDay = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();

        return dateObj.getFullYear() + "-" + formattedMonth + "-" + formattedDay;
    }

    /**
     * Formats the date according to `yyyy-mm-dd` format out of moment.js class object
     *
     * @author Sukhdeep Singh
     * @param {Date} dateObj the `Date` object to use when formatting the date.
     * @return {string} date in yyyy-mm-dd format
     */
    getFormattedMomentDate(dateObj: moment.Moment): string | null {
        return dateObj ? dateObj.format('YYYY-MM-DD') : null;
    }

    /**
     * Creates a new moment.js object from a string date or returns empty moment object
     * @param {string} date the date to be converted to moment object
     * @return {Object}
     */
    getMomentObjectFromDateString(date?: string): moment.Moment {
        return date ? moment(date, "YYYY-MM-DD") : moment();
    }


    /**
     * Adds `x` number of days to the given `Date` object
     *
     * @author Sukhdeep Singh
     * @return {Date} the date object with `x` number of days added to it
     */
    addDays(objDate: Date, numOfDays: number): Date {
        objDate.setDate(objDate.getDate() + numOfDays);
        return objDate;
    }

    /**
     * Gets the access control data from `localStorage` and return data for the provided section
     *
     * @author Sukhdeep Singh
     * @param {string} sectionName name of the section to return data for
     * @return {Array} An array containing access control data for the provided section
     */
    getAccessControlData(sectionName: string = ""): any {
        let accessParamsData = [];
        const accessParams = localStorage.getItem("access_params");
        if (accessParams) {
            accessParamsData = JSON.parse(accessParams);
            if (sectionName) {
                for (let i: number = 0, len: number = accessParamsData.length; i < len; i++) {
                    if (accessParamsData[i]['module'] == sectionName)
                        return accessParamsData[i];
                }
            }
        }
        return accessParamsData;
    }

    /**
     * Generates a random string of given number of characters
     *
     * @author Sukhdeep Singh
     * @param {number} length the number of characters in random string
     * @return {string} Randomly generated string
     */
    generateRandomString(length: number = 12): string {
        const possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";

        for (let i = 0; i < length; i++) {
            randomString += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
        }

        return randomString;
    }

    /**
     * Fetches dropdown data from network
     *
     * @author Sukhdeep Singh
     * @param {Object} params any parameters to pass along with the request
     * @return {Observable} An observable containing the dropdown data
     */
    fetchDropDownData(params: any = {}): Observable<Array<any>> {
        const data: Array<any> = [];
        return Observable.create(observer => {
            this.makeRequest(params).subscribe(
                httpDataPayload => {
                    const records: Record[] = httpDataPayload && (<Records>httpDataPayload).records ? (<Records>httpDataPayload).records : null;
                    if (records) {
                        for (let i = 0, len: number = records.length; i < len; i++) {
                            data.push(records[i].record);
                        }
                    }
                },
                error => observer.error(error),
                () => {
                    //format the data returned from server and return it to the listening component
                    observer.next(data);
                    observer.complete();
                }
            );
        });
    }

    /**
     * Get the name of the selected item in a dropdown
     *
     * @author Sukhdeep Singh
     * @param {Array} arrDropdown the dropdown array to be searched
     * @param {string} id the selected id in this dropdown
     * @param {string} key the index of array to
     * @return {string} name of the selected item
     */
    getName(arrDropdown: Array<any>, id: string | number, key: string = "name"): string {
        let name: string = "";
        if (arrDropdown && id) {
            for (let i: number = 0, len: number = arrDropdown.length; i < len; i++) {
                if (arrDropdown[i].id == id) {
                    name = arrDropdown[i][key];
                    break;
                }
            }
        }
        return name;
    }
}
