import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService, AuthGuard } from '../shared/index';

@Component({
    selector: 'home-component',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
    constructor(private commonService: CommonService) {
    }

    currentUserFullName: string = "";

    ngOnInit() {
        this.currentUserFullName = this.commonService.getCurrentUserFullName();
    }

    ngAfterViewInit() {
        setTimeout(() => this.commonService.initMaterialElements(), 100);
    }
}


